import React from "react";
import heroFlower from "../../../src/assets/Assets/Pngs/heroflowers2.png";
import aloneLine from "../../../src/assets/Assets/Pngs/aloneLine.png";
import './sllider.css'
import LeftSlide from "@/Components/Sliders/LeftSlide.jsx";
import PropTypes from "prop-types";


const SliderTwo = ({dots}) => (<div className="max-w-[1250px] mx-auto">
        <div className="flex flex-col-reverse gap-6 items-center lg:items-start lg:flex-row lg:gap-12">
            <div className="max-w-[340px]">
                <LeftSlide/>
            </div>

            <div className="2xl:min-w-[804px]">

                <div className="flex justify-center ">
                    {dots}
                </div>

                <div className="philosopher">
                    <h1 className="heading_custom">
                        Have you ever needed therapy but felt held back by{" "}
                        <strong className="bg-[#FFA3A4] px-2 pt-0 rounded-xl">stigma</strong> or{" "}
                        <strong className="bg-[#fac084] px-2 pt-0 rounded-xl">high costs?</strong>
                    </h1>
                    <h3 className="flex flex-col lg:flex-row items-center justify-center italic tag mt-4">
                        You’re not alone{" "}
                        <img src={aloneLine} alt="Alone Line" className="w-[70px] h-[10px] mx-1"/>{" "}
                        75% of people feel the same way.
                    </h3>
                </div>
            </div>
        </div>

        <img
            src={heroFlower}
            alt="Hero Flower"
            className="static lg:absolute bottom-0 right-0 w-[100%] lg:w-[50%] xl:w-[42%] 2xl:w-[32%] object-contain"
            style={{
                clipPath: "inset(0 0 0 0)", maskImage: "linear-gradient(to bottom, black 50%, transparent 100%)", WebkitMaskImage: "linear-gradient(to bottom, black 50%, transparent 100%)",
            }}
        />
    </div>);

SliderTwo.propTypes = {
    dots: PropTypes.node.isRequired
};

export default SliderTwo;