import React, {useState} from 'react';
import planicon from "../assets/Assets/Svgs/planicon.svg";
import background from "@/assets/Assets/Svgs/backgroundv2.svg";
import subscritionbg from "@/assets/Assets/Svgs/subscritionbg.svg";

import TherapyPlan from "@/Components/TherapyPlan.jsx";
import SubscriptionPlan from "@/Components/SubscriptionPlan.jsx";
import './header.css'

const Plans = () => {
    const [selectedPlan, setSelectedPlan] = useState("therapy");
    return (
        <div className="w-full py-12" style={{
            backgroundImage: selectedPlan === 'subscription' ? `url(${subscritionbg})` : `url(${background})`,
            backgroundSize: selectedPlan === 'subscription' ? 'cover' : 'auto',
        }}>
            <div
                className="max-w-[85%] py-4 m-auto"
            >
                {/* Header Section */}
                <div className="flex flex-col items-center mb-8 px-4 gap-3 max-w-[1200px] m-auto">
                    <h1
                        className="heading philosopher"
                    >
                        Design Your Mental Health Experience
                    </h1>
                    <p className="sub_heading philosopher ">
                        Select the best option for your mental health goals.
                        <br/> Want 24/7 guidance and self-help? Choose a subscription plan.
                        <br/> Need one-on-one therapy from a licensed therapist? Choose a package to proceed.
                    </p>
                </div>

                {/* Toggle Buttons */}
                <div className="flex justify-center mb-8">
                    <div className="bg-white gap-4 items-center rounded-2xl lg:gap-3 lg:rounded-full shadow-md px-4 py-3 flex flex-col lg:flex-row">
                        <button
                            className={`w-[220px] 2xl:w-[260px] px-6 py-3 transition-all duration-300 ease-in-out transform ${
                                selectedPlan === "subscription"
                                    ? "border-2 border-[#9fc1bb] rounded-full bg-gradient-to-b from-[#77AFA4] to-[#619288] text-white shadow-md"
                                    : "border-2 border-[#9fc1bb] rounded-full bg-white text-[#619288] shadow-sm hover:shadow-md"
                            } focus:outline-none`}
                            onClick={() => setSelectedPlan("subscription")}
                        >
                            Subscription Plans
                        </button>
                        <button
                            className={`w-[220px] 2xl:w-[260px] px-6 py-3 transition-all duration-300 ease-in-out transform ${
                                selectedPlan === "therapy"
                                    ? "border-2 border-[#9fc1bb] rounded-full bg-gradient-to-b from-[#77AFA4] to-[#619288] text-white shadow-md"
                                    : "border-2 border-[#9fc1bb] rounded-full bg-white text-[#619288] shadow-sm hover:shadow-md"
                            } focus:outline-none`}
                            onClick={() => setSelectedPlan("therapy")}
                        >
                            Therapy Sessions
                        </button>
                    </div>
                </div>


                {/* Plans Section */}

                {selectedPlan === "therapy" && <TherapyPlan/>}
                {selectedPlan === "subscription" && <SubscriptionPlan/>}


                {/* Divider */}
                <div
                    className="border-t border-[#77AFA4] border-dashed my-12 mx-auto"
                    style={{
                        borderWidth: "1px",
                        borderStyle: "dashed",
                    }}
                ></div>

                <div className="text-center flex flex-col justify-center w-full">
                    {/* Section Title */}
                    <h2 className="text-lg md:text-[22px] font-normal leading-9 ">
                        Benefits of our Therapy Sessions
                    </h2>

                    <div className="flex flex-col lg:flex-row lg:px-0 justify-between gap-12 py-12 ">

                        {/* Benefit Item 1 */}
                        <div className="flex flex-row items-center space-x-4">
                            <img src={planicon} alt="On-demand Therapy" className="w-16 h-16 rounded-full"/>
                            <p className="text-base md:text-lg text-left">
                                On-demand audio and video therapy
                                with a qualified therapist.
                            </p>
                        </div>

                        {/* Benefit Item 2 */}
                        <div className="flex flex-row items-center space-x-4">
                            <img src={planicon} alt="Unlimited Messaging" className="w-16 h-16 rounded-full"/>
                            <p className="text-base md:text-lg text-left">
                                Unlimited messaging with a licensed
                                professional.
                            </p>
                        </div>

                        {/* Benefit Item 3 */}
                        <div className="flex flex-row items-center space-x-4">
                            <img src={planicon} alt="Confidential Sessions" className="w-16 h-16 rounded-full"/>
                            <p className="text-base md:text-lg text-left">
                                Confidential sessions with
                                guaranteed privacy.
                            </p>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default Plans;