import React, { useEffect, useState } from "react";
import background from "../assets/Assets/Svgs/backgroundv2.svg";
import faqDownArrow from "@/assets/Assets/Svgs/arrow-down-faq.svg";
import faqUpArrow from "@/assets/Assets/Svgs/arrow-up-faq.svg";
import Loader from "@/Components/loader.jsx";
import './header.css'

const Faqs = () => {
    const [expanded, setExpanded] = useState(null);
    const [data, setData] = useState(null);

    const handleToggle = (index) => {
        setExpanded(expanded === index ? null : index);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(
                    import.meta.env.VITE_BASE_API_URL + "/web/faqs"
                );
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const fetchedData = await response.json();
                const cleanedData = fetchedData?.data.map(faq => ({
                    ...faq,
                    answer: faq.answer.replace(/<\/?p>/g, '').trim()
                }));

                setData(cleanedData);
            } catch (error) {
                console.error("There was a problem with the fetch operation:", error);
            }
        }

        fetchData().then(r => {
            console.log(r.data);
        });
    }, []);

    if (!data) {
        return (
            <div
                className="w-full py-6 mt-12 mb-12"
                style={{ backgroundImage: `url(${background})` }}
            >
                <div className="max-w-[85%] m-auto flex flex-col lg:flex-row gap-8 justify-center items-center">
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <div
            // mt-12 mb-12
            className="w-full py-6"
            style={{ backgroundImage: `url(${background})` }}
        >
            <div
                className="max-w-[85%] m-auto flex flex-col lg:flex-row gap-8"
            >
                <div className="flex justify-between md:justify-start w-full md:w-[30%]">
                    {/*<h1 className="text-3xl md:text-4xl 2xl:text-5xl font-bold text-[#002C3C] philosopher leading-tight">*/}
                    <h1 className="heading philosopher">
                        FAQs
                    </h1>
                </div>
                <div
                    className="w-full lg:w-[70%] grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-5"
                >
                    {data.map((faq, index) => (
                        <div
                            key={faq?.id}
                            className={`w-full flex flex-col justify-center gap-5 px-6 py-2 border border-[#F0C2C9] rounded-ss-[32px] rounded-es-[14px] rounded-se-[32px] rounded-ee-[32px] transition-all duration-300 ${
                                expanded === index ? "h-auto" : "h-[80px]"
                            }`}
                            onClick={() => handleToggle(index)}
                            style={{
                                cursor: "pointer",
                                overflow: "hidden",
                            }}
                        >
                            <div className="w-full flex justify-between items-center">
                                <p className="xl:text-lg max-w-[250px] xl:max-w-full">{faq.question}</p>
                                {expanded === index ? (
                                    <img
                                        src={faqUpArrow}
                                        alt="faq arrow"
                                        className="w-3 object-contain"
                                    />
                                ) : (
                                    <img
                                        src={faqDownArrow}
                                        alt="faq arrow"
                                        className="w-3 object-contain"
                                    />
                                )}
                            </div>
                            {expanded === index && (
                                <p className="font-thin">{faq.answer}</p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Faqs;