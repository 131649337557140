import React, { useState, useEffect } from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
import { useSignUpContext } from "./SignUpContext";
import {toast} from "sonner";

const SignupFormFour = ({ setProgress, setStep }) => {
    useEffect(() => {
        setProgress(5);
    }, [setProgress]);

    const { setSignupData, signupData } = useSignUpContext();

    const [option, setOption] = useState(signupData.current_workplace);
    const [yearsOfExperience, setYearsOfExperience] = useState(signupData.years_of_experience || "");
    const [filled, setFilled] = useState(true);

    const handleClick = () => {
        const errors = [];

        if (!option) {
            errors.push("Please select an option that describes you.");
        }

        if (!yearsOfExperience) {
            errors.push("Please specify your years of experience.");
        } else if (isNaN(yearsOfExperience) || yearsOfExperience < 0) {
            errors.push("Please enter a valid number for years of experience.");
        }

        if (errors.length > 0) {
            setFilled(false);
            toast.error(errors.join(" "));
        } else {
            setSignupData((prevData) => ({
                ...prevData,
                current_workplace: option,
                years_of_experience: yearsOfExperience,
            }));
            setStep(6);
        }
    };

    return (
        <div>
            <div
                style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
                className="flex flex-col shadow-lg items-center h-[100%] bg-white pb-8 rounded-[20px] mt-3 z-1 max-[767px]:w-[100%] tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px] tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] max-[767px]:pb-8 max-[1290px]:pb-[2%] lg:overflow-y-scroll lg:overscroll-none"
            >
                <div className="pt-[8%] laptop:pt-6 text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
                    <img alt="" src={profileIcon} />
                    <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
                        Where do you currently work?
                    </h1>
                    <p>Select an option to proceed.</p>
                </div>

                <div className="w-full">
                    <p
                        className={`text-red-700 w-full ${
                            filled ? "opacity-0" : "opacity-1"
                        } tracking-wider text-center my-2`}
                    >
                        All fields must be filled correctly!
                    </p>
                    <div className="flex flex-col justify-center items-center gap-4 mb-4 w-full">
                        {[
                            "Private Clinic/Hospital",
                            "Government Clinic/Hospital",
                            "Online Platform",
                            "Currently Unemployed",
                            "Other",
                        ].map((workplace) => (
                            <button
                                key={workplace}
                                value={workplace}
                                onClick={(e) => setOption(e.target.value)}
                                className={`w-[90%] ${
                                    option === workplace ? "bg-[#1A6A73] text-white " : ""
                                } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
                            >
                                {workplace}
                            </button>
                        ))}
                    </div>

                    {/* YEARS OF EXPERIENCE */}
                    <div className="bg-[#F9F7F3] w-[90%] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[1130px]:rounded-[20px] max-[767px]:w-[100%] max-[767px]:rounded-[15px] mx-auto">
                        <p className="text-[15px] font-semibold text-[#191B1E]">
                            Years of Experience
                        </p>
                        <input
                            type="text"
                            value={yearsOfExperience}
                            onChange={(e) => {
                                const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                setYearsOfExperience(numericValue);
                            }}
                            className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px] max-[767px]:w-[100%] border border-gray-300 rounded-[10px] px-2"
                            placeholder="Enter years of experience"
                        />
                    </div>

                    <button
                        onClick={handleClick}
                        className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-[3%] text-[white] rounded-[30px]"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SignupFormFour;
