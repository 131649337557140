import React, { useState, useEffect } from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
import { useSignUpContext } from "./SignUpContext";
import {toast} from "sonner";

const SignupFormSeven = ({ setProgress, setStep }) => {
  // const [nextModal, setNextModal] = useState(false);
  useEffect(() => {
    setProgress(8);
  }, [setProgress]);

  const { setSignupData, signupData } = useSignUpContext();

  const [option, setOption] = useState(signupData.intended_weekly_time);
  const [filled, setFilled] = useState(true);

  const handleClick = () => {
    if (option && option.trim() !== "") {
      setSignupData((prevData) => ({
        ...prevData,
        intended_weekly_time: option,
      }));
      setStep(9);
    } else {
      setFilled(false);
      toast.error('Please select a valid option for your intended weekly time');
    }
  };

  return (
    <div>
      <div
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="flex flex-col pb-8 shadow-lg items-center h-[100%] bg-white  rounded-[20px] mt-3 z-1  max-[767px]:pb-8 tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] max-[1290px]:pb-[2%] lg:overflow-y-scroll lg:overscroll-none"
      >
        <div className="pt-[8%] text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%] ">
          <img alt="" src={profileIcon} />
          <h1 className="text-[24px] font-bold ">
            How much time do you intend to spend on Mentra?
          </h1>
          <p>Select an option to proceed.</p>
        </div>

        <div className=" w-[100%]">
          <p
            className={` text-red-700 w-full ${
              filled ? "opacity-0" : "opacity-1"
            } tracking-wider text-center my-2`}
          >
            Please select an option!
          </p>
          <div className="flex flex-col justify-center items-center gap-4 mb-2 w-full">
            <button
              value="5 hours/week"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "5 hours/week" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              Up to 5 hours/week
            </button>
            <button
              value="5-9 hours/week"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "5-9 hours/week" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              5-9 hours/week
            </button>
            <button
              value="10-19 hours/week"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "10-19 hours/week" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              10-19 hours/week
            </button>
            <button
              value="20-29 hours/week"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "20-29 hours/week" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              20-29 hours/week
            </button>
            <button
              value="> 30 hours/week"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "> 30 hours/week" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              &gt; 30 hours/week
            </button>
          </div>
          <div>
            <button
              onClick={handleClick}
              className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-[3%] text-[white] rounded-[30px]"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupFormSeven;
