import React from 'react';
import available from "../../assets/Assets/Svgs/available.svg";
import care from "../../assets/Assets/Svgs/care.svg";
import free from "../../assets/Assets/Svgs/free.svg";
import unique from "../../assets/Assets/Svgs/unique.svg";
import hug from "../../assets/Assets/Svgs/hug.svg";
import love from "../../assets/Assets/Svgs/love.svg";
import empower from "../../assets/Assets/Svgs/empower.svg";
import house from "../../assets/Assets/Svgs/house.svg";
import sensitive from "../../assets/Assets/Svgs/sensitive.svg";
import privateIcon from "../../assets/Assets/Svgs/private.svg";

const pillsData = [
    { img: unique, text: "Unique to You" },
    { img: privateIcon, text: "Completely Private" },
    { img: free, text: "Judgment Free" },
    { img: sensitive, text: "Culturally Sensitive" },
    { img: love, text: "Understanding and Kind" },

    { img: empower, text: "Empowering Self-care" },
    { img: care, text: "Unbiased Listening Ear" },
    { img: available, text: "Always Available" },
    { img: hug, text: "Your Companion" },
    { img: house, text: "Your Safe Haven" },
];

// eslint-disable-next-line react/prop-types
const Pill = ({ img, text }) => (
    <div className="flex py-2 pl-2 pr-6 rounded-[50px] items-center bg-white shadow-pill max-[767px]:w-full">
        <img alt="" className="w-[48px] h-[48px]" src={img} />
        <p className="ml-[12px] font-normal text-[14px] max-[767px]:text-[14px]">{text}</p>
    </div>
);

const PillContainer = () => {
    return (
        <div className="flex justify-center w-full py-12">
            <div className="flex flex-wrap justify-center gap-4 max-w-[1400px] px-4">
                {pillsData.map((pill, idx) => (
                    <div className="max-[767px]:w-full max-[767px]:px-2" key={idx}>
                        <Pill img={pill.img} text={pill.text} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PillContainer;