import React from 'react';
import {useState, useEffect} from "react";
import {ChevronDown, ChevronUp} from "lucide-react";
import logo from "../assets/Assets/Svgs/logov2.svg";
import background from "../assets/Assets/Svgs/backgroundv2.svg";
import {Link, useLocation, NavLink} from "react-router-dom";
import menu from "../assets/Assets/Pngs/menu.png";
import Waitlist from "../Components/Waitlist";
import {AnimatePresence, motion} from "framer-motion";

const Header = () => {
    const location = useLocation();

    const hiddenPaths = ["/signup", "/login", "/dashboard", `/video-chat`, "/start-call", "/forgotPasscode"];

    const shouldHideHeader = hiddenPaths.includes(location.pathname) || location.pathname.includes("dashboard") || location.pathname.includes("video-chat") || location.pathname.includes("start-call");

    const [activeLink, setActiveLink] = useState("");
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
    };

    const onStaging = window.location.href.includes("stagingss");
    const onLocal = window.location.href.includes("localhostdd");

    useEffect(() => {
        const currentPageLink = window.location.pathname;
        const linkIndex = getLinkIndexFromPath(currentPageLink);
        setActiveLink(linkIndex);
        localStorage.setItem("activeLink", linkIndex.toString());
    }, []);

    const getLinkIndexFromPath = (path) => {
        switch (path) {
            case "/":
                return 0;
            case "/about":
                return 1;
            case "/mentra-for-work":
                return 2;
            case "/for-therapist":
                return 3;
            case "/wellness-library":
                return 4;
            case "/contact":
                return 5;
            default:
                return 0; // Default to the first link if path is not recognized
        }
    };

    const handleClick = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleLinkClick = (index) => {
        setActiveLink(index);
        setIsDropdownVisible(false);
        // Store active link index in local storage
        localStorage.setItem("activeLink", index.toString());
    };
    const variants = {
        initial: {
            x: "70vw",
        },
        animate: {
            x: 0,
            transition: {
                ease: "easeInOut",
                duration: 0.5,
            },
        },
        exit: {
            x: "70vw",
            transition: {
                ease: "easeOut",
                duration: 0.5,
            },
        },
    };
    return (
        !shouldHideHeader && (
            <div
                className="w-screen fixed top-0 z-[100] bg-white"
                // className="px-[10%] tablet:py-4 laptop:py-[1%] top-0 fixed w-screen z-10 max-[767px]:py-4"
                style={{
                    backgroundImage: `url(${background})`
                }}>

                <div className="max-w-[85%] py-4 m-auto">
                    <div className="justify-between items-center hidden max-[1200px]:flex">
                        <div
                            onClick={() => handleLinkClick(0)}
                            className="w-[35vw] max-w-[150px] tablet:max-w-[180px]"
                        >
                            <Link to="/">
                                <img alt="logo" src={logo}/>
                            </Link>
                        </div>
                        {isDropdownVisible && (
                            <div
                                onClick={() => setIsDropdownVisible(false)}
                                className="bg-transparent z-0 left-0 block w-screen h-screen top-0 absolute laptop:hidden"
                            ></div>
                        )}
                        <div className="flex justify-end w-[30%]">
                            <img
                                alt="menu"
                                onClick={handleClick}
                                className="menu cursor-pointer"
                                src={menu}
                            />
                            <AnimatePresence>
                                {isDropdownVisible && (
                                    <motion.div
                                        key="mobmenu"
                                        variants={variants}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        className="bg-gray-50 z-10 text-[16px] tablet:text-[18px] px-5 py-5 tablet:px-7 w-fit  mt-[10%] ml-[-32%] flex-col flex absolute shadow-lg rounded-[10px]"
                                    >
                                        <NavLink
                                            onClick={() => handleLinkClick(1)}
                                            to="/about"
                                            activeStyle={{fontWeight: "700", color: "#1A6A73"}}
                                            className={({isActive}) =>
                                                isActive
                                                    ? " my-[4%] font-bold text-[#1A6A73]"
                                                    : "my-[4%] font-normal"
                                            }
                                        >
                                            About
                                        </NavLink>

                                        {/*<NavLink*/}
                                        {/*  onClick={() => handleLinkClick(2)}*/}
                                        {/*  to="/mentra-for-work"*/}
                                        {/*  activeStyle={{ fontWeight: "700", color: "#1A6A73" }}*/}
                                        {/*  className={({ isActive }) =>*/}
                                        {/*    isActive*/}
                                        {/*      ? " my-[4%] font-bold text-[#1A6A73]"*/}
                                        {/*      : "my-[4%] font-normal"*/}
                                        {/*  }*/}
                                        {/*>*/}
                                        {/*  Mentra for Work*/}
                                        {/*</NavLink>*/}

                                        {/*<NavLink*/}
                                        {/*  onClick={() => handleLinkClick(3)}*/}
                                        {/*  to="/for-therapist"*/}
                                        {/*  activeStyle={{ fontWeight: "700", color: "#1A6A73" }}*/}
                                        {/*  className={({ isActive }) =>*/}
                                        {/*    isActive*/}
                                        {/*      ? " my-[4%] font-bold text-[#1A6A73]"*/}
                                        {/*      : "my-[4%] font-normal"*/}
                                        {/*  }*/}
                                        {/*>*/}
                                        {/*  For Coaches*/}
                                        {/*</NavLink>*/}

                                        <NavLink
                                            onClick={() => handleLinkClick(4)}
                                            to="/wellness-library"
                                            activeStyle={{fontWeight: "700", color: "#1A6A73"}}
                                            className={({isActive}) =>
                                                isActive
                                                    ? " my-[4%] font-bold text-[#1A6A73]"
                                                    : "my-[4%] font-normal"
                                            }
                                        >
                                            Wellness Library
                                        </NavLink>

                                        <NavLink
                                            onClick={() => handleLinkClick(5)}
                                            to="/contact"
                                            activeStyle={{fontWeight: "700", color: "#1A6A73"}}
                                            className={({isActive}) =>
                                                isActive
                                                    ? " my-[4%] font-bold text-[#1A6A73]"
                                                    : "my-[4%] font-normal"
                                            }
                                        >
                                            Contact
                                        </NavLink>
                                        <div className="flex flex-col gap-3">
                                            {/* {onStaging || onLocal ? ( */}
                                            <a href="#footer">
                                                <button className="bg-[#20232D] text-white h-[45px] text-sm py-2 px-4 rounded-full shadow-lg mx-auto font-normal cursor pointer">
                                                    Get the App
                                                </button>
                                            </a>
                                            <Link to="/login">
                                                <button className="bg-[#1A6A73] text-white h-[45px] text-sm py-2 px-4 rounded-full shadow-lg mx-auto font-normal cursor pointer">
                                                    Coach Login
                                                </button>
                                            </Link>
                                            {/* ) : ( */}
                                            {/* )} */}
                                        </div>
                                        {/* )} */}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </div>
                    <div className="flex justify-between items-center max-[1200px]:hidden">
                        <div onClick={() => handleLinkClick(0)} className="w-[20%]">
                            <Link to="/">
                                <img alt="logo" className="" src={logo}/>
                            </Link>
                        </div>
                        <div
                            className="flex flex-row items-center gap-12"
                        >
                            <NavLink
                                onClick={() => handleLinkClick(1)}
                                to="/about"
                                activeStyle={{fontWeight: "700", color: "#1A6A73"}}
                                className="transition-all text-[#002C3C] duration-200 ease-in-out hover:scale-110"
                                style={({isActive}) => {
                                    return {
                                        fontWeight: isActive ? "700" : "",
                                        transform: isActive ? "scale(1.1)" : "",
                                    };
                                }}
                            >
                                About
                            </NavLink>

                            <NavLink
                                onClick={() => handleLinkClick(4)}
                                to="/wellness-library"
                                activeStyle={{fontWeight: "700", color: "#1A6A73"}}
                                className="transition-all text-[#002C3C] duration-200 ease-in-out hover:scale-110"
                                style={({isActive}) => {
                                    return {
                                        fontWeight: isActive ? "700" : "",
                                        transform: isActive ? "scale(1.1)" : "",
                                    };
                                }}
                            >
                                Wellness Library
                            </NavLink>

                            <NavLink
                                onClick={() => handleLinkClick(2)}
                                to="/mentra-for-work"
                                activeStyle={{fontWeight: "700", color: "#1A6A73"}}
                                className="transition-all text-[#002C3C] duration-200 ease-in-out hover:scale-110"
                                style={({isActive}) => {
                                    return {
                                        fontWeight: isActive ? "700" : "",
                                        transform: isActive ? "scale(1.1)" : "",
                                    };
                                }}
                            >
                                Mentra for Work
                            </NavLink>
                        </div>
                        <div className="relative hidden min-[1240px]:flex justify-end gap-3">
                            <a href="#footer">
                                <button
                                    className="text-[#002C3C] bg-white border border-[#98BCB6] h-[45px] text-sm py-2 px-6 rounded-2xl shadow-lg mx-auto font-normal cursor-pointer"
                                >
                                    Get App
                                </button>
                            </a>
                            <Link to='/signup'
                                  onClick={() => setIsOpen(!isOpen)}
                                  className="bg-[#98BCB6] text-white h-[45px] text-sm py-2 px-6 rounded-2xl shadow-lg flex items-center gap-2"
                            >
                                For Coaches
                                {/*Register {isOpen ? <ChevronUp size={16}/> : <ChevronDown size={16}/>}*/}
                            </Link>
                            {/* TODO FOR LATER REUSE */}
                            {/*{isOpen && (*/}
                            {/*    <div className="absolute mt-12">*/}
                            {/*        <Link to="/signup">*/}
                            {/*            <button className="bg-[#98BCB6] text-white h-[45px] text-sm py-2 px-6 rounded-2xl shadow-lg">*/}
                            {/*                For Coaches*/}
                            {/*            </button>*/}
                            {/*        </Link>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                    </div>
                    <AnimatePresence>
                        {isModalOpen && <Waitlist setModalOpen={setModalOpen}/>}
                    </AnimatePresence>
                </div>
            </div>
        )
    );
};

export default Header;
