import React, {useEffect, useState} from "react";
import ReactGA from "react-ga4";
import {useLocation, useParams, Link, useNavigate} from "react-router-dom";
import {getCourseContentById} from "../../Api";
import arrow from "../../assets/Assets/Images/arrow-left.svg";
import Loader from "../../Components/loader";
import JoinMentra from "../../Components/JoinMentra";
import Footer from "../../Components/Footer";
import Title from "@/Components/Title.jsx";

const cleanHtml = (html) => {
    return html
        .replace(/<p>&nbsp;<\/p>/g, "") // Remove empty <p> tags with non-breaking spaces
        .replace(/<p>\s*<\/p>/g, "");   // Remove completely empty <p> tags
};

function WellnessDetail() {
    const location = useLocation();
    const {id} = useParams();
    const [course, setCourse] = useState(null);
    const navigate = useNavigate();
    const [processedBody, setProcessedBody] = useState("");

    useEffect(() => {
        if (!course?.body) return;

        let transformed = course.body;

        transformed = transformed
            .replace(/<strong>(.*?)<\/strong>/g, '<h2 class="text-2xl font-bold mb-2">$1</h2>')
            .replace(/<p>(.*?)<\/p>/g, '<p class="text-base leading-relaxed">$1</p>')
            .replace(/<ul>(.*?)<\/ul>/g, '<ul class="list-disc pl-5 space-y-2">$1</ul>')
            .replace(/<li>(.*?)<\/li>/g, '<li class="text-base leading-relaxed">$1</li>');

        setProcessedBody(transformed);
    }, [course?.body]);

    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: window.location.pathname});
    }, []);

    useEffect(() => {
        if (location.state) {
            setCourse(location.state);
        }
        console.log(location.state);
    }, [location]);

    useEffect(() => {
        const handlePopState = () => {
            navigate("/wellness-library");
        };
        window.addEventListener("popstate", handlePopState);
        return () => window.removeEventListener("popstate", handlePopState);
    }, [navigate]);

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await getCourseContentById(id);
                const data = response.data;
                setCourse({
                    ...data.data,
                    body: cleanHtml(data.data.body), // Clean the body HTML
                });
                console.log(data.data);
            } catch (error) {
                console.error("Error fetching courses:", error.message);
            }
        };
        if (!course) fetchCourse();
    }, [course, id]);

    if (!course) {
        return (
            <div className="pt-[200px]">
                <div className="pt-[7%] pb-[5%] tablet:py-[5%]">
                    <Loader/>
                </div>
            </div>
        );
    }
    console.log(course.body)
    return (
        <div>
            <Title
                title={course?.title}
                description={course?.description}
                keywords={course?.keywords}
                ogTitle={course?.title}
                ogDescription={course?.description}
                ogImage={course?.cover?.url}
                ogUrl={window.location.href}
            />
            <div className="flex justify-center flex-col items-center pt-[100px] max-[767px]:pt-16">
                <div className="flex flex-col items-center justify-center max-[767px]:mt-14 mt-[2%]">
                    <Link to="/wellness-library">
                        <div className="flex justify-center items-center">
                            <img alt="arrow" src={arrow}/>
                            <h1 className="text-[14px] font-semibold ml-[5px] max-[767px]:text-[12px]">
                                Back to Library
                            </h1>
                        </div>
                    </Link>
                    <h1 className="mt-[2%] font-semibold max-[767px]:text-[14px]">
                        Published {new Date(course.created_at).toISOString().split("T")[0]}
                    </h1>
                    <h1 className="philosopher laptop:text-[48px] font-bold text-center pt-7 tablet:text-[38px] px-[5vw] leading-tight text-[28px]">
                        {course.title}
                    </h1>
                    <h1
                        dangerouslySetInnerHTML={{__html: course.description}}
                        className="text-[#020817] text-center mx-[10%] text-base font-normal pt-3 max-[767px]:text-center max-[767px]:text-sm"
                    />
                </div>
                <div className="mt-8 overflow-hidden tablet:px-[10vw] laptop:px-[20vw] w-full max-h-[35vh] laptop:max-h-[58vh]">
                    <img
                        alt="courseImage"
                        className="w-full object-cover object-center max-h-[35vh] laptop:max-h-[58vh] max-w-[950px] mx-auto"
                        src={course.cover.url}
                    />
                </div>

                <div className="my-[5%] flex flex-col justify-center items-center max-[767px]:pl-[0]">
                    <div

                        className="laptop:mx-[25%] mx-[5vw] space-y-6 text-[#475467] blog-layout"
                        dangerouslySetInnerHTML={{__html: processedBody}}
                    />

                    {(course.attachments?.length ?? []) > 0 && (
                        <>
                            <div className="w-full">
                                <h2 className="laptop:mx-[25%] mx-[5vw] mt-5">
                                    Attachments: Ensure you listen with earphones
                                </h2>
                                {course.attachments.map((attachment, index) => (
                                    <div key={index}>
                                        {attachment.file?.mime_type === "mp3" ? (
                                            <div
                                                className="flex flex-col tablet:flex-row items-start tablet:text-[20px] laptop:mx-[25%] mx-[5vw]  tablet:items-center justify-start mt-4 gap-2 tablet:gap-4">
                                                <audio controls>
                                                    <source src={attachment.file.url} type="audio/mp3"/>
                                                    Your browser does not support the audio tag.
                                                </audio>
                                            </div>
                                        ) : (
                                            attachment.file.url && (
                                                <div className="mt-6 overflow-hidden px-0  tablet:text-[20px] laptop:px-[25%] tablet:px-[10vw] mx-auto  w-full max-h-[35vh] laptop:max-h-[58vh]">
                                                    <img
                                                        className="w-full object-cover  object-center max-h-[35vh] laptop:max-h-[58vh] max-w-[950px]  mx-auto"
                                                        src={attachment.file.url}
                                                        alt="AttachmentImage"
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <JoinMentra/>
            <Footer/>
        </div>
    );
}

export default WellnessDetail;