import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from "prop-types";


function Title({title, description, keywords, ogTitle, ogDescription, ogImage, ogUrl, twitterCard}) {
    return (
        <Helmet>
            {/* Default Meta Tags */}
            <title>{title} - Mentra</title>
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>

            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={ogTitle || title}/>
            <meta property="og:type" content="article"/>
            <meta property="og:description" content={ogDescription || description}/>
            <meta property="og:image" content={ogImage}/>
            <meta property="og:url" content={ogUrl}/>

            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content={twitterCard || 'summary_large_image'}/>
            <meta name="twitter:title" content={ogTitle || title}/>
            <meta name="twitter:description" content={ogDescription || description}/>
            <meta name="twitter:image" content={ogImage}/>
        </Helmet>
    );
}


Title.defaultProps = {
    title: 'Mentra',
    description: 'Your path to mental and emotional wellbeing.',
    keywords: 'mental health, wellbeing, emotional support',
    ogImage: 'https://yourmentra.com/assets/logov2-839tCyk0.svg',
    ogUrl: 'https://yourmentra.com/',
};

Title.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    ogTitle: PropTypes.string,
    ogDescription: PropTypes.string,
    ogImage: PropTypes.string,
    ogUrl: PropTypes.string,
    twitterCard: PropTypes.string,
}

export default Title;
