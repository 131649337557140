import React from 'react';
import {useState, useEffect} from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
import "./style.css";
import cation from "../../assets/Assets/Svgs/CautionIcon.svg";
import success from "../../assets/Assets/Images/success-check.png";

import {Link} from "react-router-dom";
import {useSignUpContext} from "./SignUpContext";
import {submitTherapistSignup} from "@/Api.js";
import {ContactLoader} from "../loader";
import {toast} from "sonner";

const SignupForm11 = ({setProgress}) => {
    useEffect(() => {
        setProgress(12);
    }, [setProgress]);

    const {setSignupData, signupData} = useSignUpContext();

    const [option, setOption] = useState([...signupData.techniques_of_expertise]);
    const [filled, setFilled] = useState(true);
    const [progress, setSubmitProgress] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);

    const [error, setError] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    console.log(errorMessage);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsCheckboxChecked(event.target.checked);
    };

    const handleButtonClick = () => {
        if (!isCheckboxChecked) {
            toast.error("Please select the checkbox to proceed further.");
            return;
        }

        if (option.length === 0) {
            toast.error("Please select at least one technique of expertise before proceeding.");
            return;
        }

        openModal();
    };


    const [ndaPdf] = useState("../../src/assets/Assets/Pdfs/NDA_Therapists.pdf");
    const [contractPdf] = useState("../../src/assets/Assets/Pdfs/IndependentContractorTherapistTermsandConditions.pdf");


    function handleOption(e) {
        const value = e.target.value;
        if (option.includes(value)) {
            const index = option.indexOf(value);
            if (index !== -1) {
                const newOptions = [...option];
                newOptions.splice(index, 1);
                setOption(newOptions);
            }
        } else {
            setOption((option) => [value, ...option]);
        }
    }

    async function submitData(data) {
        setSubmitProgress(true);
        try {

            const formData = new FormData();
            const fields = Object.keys(data);

            fields.forEach((key) => {
                const value = data[key];
                console.log(key, value);

                if (
                    [
                        "licence",
                        "credentials",
                        "countries_lived_in",
                        "languages_spoken",
                        "treatable_conditions",
                        "techniques_of_expertise",
                    ].includes(key)
                ) {
                    value.forEach((item, index) => {
                        formData.append(`${key}[${index}]`, item);
                    });
                } else {
                    formData.append(key, value);
                }
            });

            const response = await submitTherapistSignup(formData);
            if (response.data.success) {
                setError(false);
            }
        } catch (error) {

            if (error?.response?.data?.errors) {
                const keys = Object.keys(error.response.data.errors);
                const firstKey = keys[0];
                const firstValue = error.response.data.errors[firstKey];

                setErrorMessage(firstValue[0]);
                setError(true);
            } else {
                setError(true);
            }

        } finally {
            setSubmitProgress(false);
            setModalOpen(true);
        }
    }

    const openModal = () => {
        if (option.length > 0) {
            const modifiedSignupData = {
                ...signupData,
                techniques_of_expertise: option,
                title: `CDA Licensed ${signupData.field}`,
            };
            setSignupData(modifiedSignupData);

            submitData(modifiedSignupData);
        } else {
            setFilled(false);
        }
    };

    const conditions = [
        "Cognitive Behavioral Therapy (CBT)",
        "Mindfulness-Based Therapies",
        "Emotionally Focused Therapy (EFT)",
        "Solution-Focused Therapy",
        "Trauma-FocusedTherapy",
        "Acceptance and Commitment Therapy (ACT)",
        "Motivational Interviewing",
        "Dialectical Behavior Therapy (DBT)",
        "Psychodynamic Therapy",
        "Client-Centered Therapy",
        "Family Therapy",
    ];

    function handleModalSuccess() {
        setModalOpen(false);
        window.history.back();
    }

    return (
        <div>
            <div
                style={{scrollbarWidth: "none", msOverflowStyle: "none"}}
                className="flex flex-col shadow-lg items-center h-[100%] bg-white  rounded-[20px] mb-[2%] mt-3 lg:mb-0 z-1 tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
            >
                <div className="pt-[8%] text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
                    <img alt="profile icon" src={profileIcon}/>
                    <h1 className="text-[24px] font-bold ">
                        What techniques are you an expert in?
                    </h1>
                    <p>Select all that applies</p>
                </div>

                <div className=" w-[90%] px-[2%] pb-[10%] rounded-[20px] text-center">
                    <p
                        className={` text-red-700 w-full ${
                            filled ? "opacity-0" : "opacity-1"
                        } tracking-wider text-center my-2`}
                    >
                        Please select an option!
                    </p>
                    <div className="w-[100%] text-left">
                        {conditions.map((word, index) => (
                            <button
                                value={word}
                                onClick={handleOption}
                                className={`px-[4%] m-[1%] ${
                                    option.includes(word) ? "bg-[#1A6A73] text-white" : ""
                                } border-[2px] border-[#F9F7F3] rounded-[30px] py-[2%]`}
                                key={index}
                            >
                                {word}
                            </button>
                        ))}
                    </div>
                    <div>

                        <div className="pt-[8%] text-justify flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">

                            {/* <div className="pt-[15%] text-justify flex flex-col border-b-[1px] border-[#E2E4E9]"> */}
                            <small>
                                By checking the box below, I acknowledge that I have read, understood, and agree to be bound by the&nbsp;
                                <a  href={contractPdf} target="_blank" className="underline" rel="noreferrer">
                                    <b>Independent Contractor Agreement</b></a>
                                &nbsp;and&nbsp;
                                <a href={ndaPdf} target="_blank" className="underline" rel="noreferrer">
                                    <b>Non-Disclosure Agreement</b>
                                </a>  &nbsp; provided by Mentra FZ-LLC, which I have accessed and reviewed&nbsp;
                                <a href={contractPdf} target="_blank" className="underline" rel="noreferrer">here</a> and&nbsp;
                                <a href={ndaPdf} target="_blank" className="underline" rel="noreferrer">here</a>.
                                I understand that this agreement constitutes a legally binding contract between myself and MENTRA FZ-LLC.
                            </small>
                            <div className="flex items-center mt-4">
                                <input
                                    type="checkbox"
                                    id="confirmationCheckbox"
                                    onChange={handleCheckboxChange}
                                    className="mr-2 w-4 h-4 border-2 border-gray-400 rounded-sm"
                                    style={{
                                        backgroundColor: isCheckboxChecked ? '#1A6A73' : 'white',
                                        border: '2px solid #1A6A73',
                                        cursor: 'pointer',
                                        appearance: 'none',
                                        position: 'relative',
                                    }}
                                />
                                <label htmlFor="confirmationCheckbox" className="text-sm">
                                    I have read and agree to the Independent Contractor Agreement and Non-Disclosure Agreement
                                </label>
                            </div>
                        </div>

                        <button
                            onClick={handleButtonClick}
                            disabled={!isCheckboxChecked}
                            type="button"
                            className={`bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-[8%] text-[white] rounded-[30px] ${
                                isCheckboxChecked ? "bg-[#1A6A73] border border-[#E2E4E9]" : "bg-gray-300 cursor-not-allowed"
                            }`}
                        >
                            {progress ? <ContactLoader/> : "Submit Application"}
                        </button>


                        <style jsx>{`
                            #confirmationCheckbox:checked {
                                background-color: #1A6A73; /* Background color when checked */
                                border-color: #1A6A73; /* Ensure border remains the same color */
                            }

                            #confirmationCheckbox:checked::before {
                                content: '✔'; /* Checkmark symbol */
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-size: 12px; /* Adjust font size for the checkmark */
                                color: white; /* Checkmark color */
                            }
                        `}</style>


                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className="modal-overlay modal-overlay-color">
                    <div className="modal">
                        <div className="w-[100%] flex cursor-pointer justify-end">
                            {error ? (
                                <button
                                    onClick={() => setModalOpen(false)}
                                    className="btn-close"
                                >
                                    <span className="icon-cross"></span>
                                </button>
                            ) : (
                                <button onClick={handleModalSuccess} className="btn-close">
                                    <span className="icon-cross"></span>
                                </button>
                            )}
                        </div>
                        <img
                            className="w-[70px]"
                            alt="caution"
                            src={error ? cation : success}
                        />
                        <div className="w-[90%] text-center my-[3%]">
                            <h1 className="text-[#0A090B] font-semibold text-[20px] mb-[3%]">
                                {error
                                    ? "An error occurred"
                                    : "Your application is being reviewed"}
                            </h1>
                            <p className="text-[#7F7D83]">
                                {error && errorMessage
                                    ? errorMessage
                                    : error && !errorMessage
                                        ? "An error occurred while trying to submit the form. Please try again later."
                                        : `Your application to become a Mentra therapist has been submitted
                successfully. You'll receive an email once your application is
                reviewed.`}
                            </p>
                        </div>
                        {error && (
                            <div>
                                <Link to={"/"}>
                                    <button
                                        type="button"
                                        className="bg-[#1A6A73] text-center w-[400px]  py-[4%] mt-[5%] text-[white] rounded-[30px] max-[767px]:w-[300px]"
                                    >
                                        Go home
                                    </button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SignupForm11;
