import React from 'react';

import "../../index.css";
import "./about.css";
// import WhatWeOffer from "./WhatWeOffer";
// import HowItWorks from "./HowItWorks";
import PillContainer from "./PillContainer";
import '../header.css'


const AboutMentra = () => {
    return (
        <div className="px-[0%] tablet:pt-10  laptop:pt-[5%] bg-[#FEFEFE] tablet:bg-[#FEFEFE] mt-12 mb-12">
            <div className="">
                <div className="px-6 max-w-[1200px] flex flex-col md:items-center m-auto">
                    <h1
                        className="heading philosopher design_for_u"
                        // className="text-3xl md:text-4xl 2xl:text-5xl font-bold text-[#002C3C] philosopher leading-tight"
                    >
                        Mentra is Designed for YOU!
                    </h1>
                    {/*<p className="text-[#002C3C] mt-2 tablet:text-[16px] laptop:text-[18px] font-light">*/}
                    <p className="sub_heading design_for_u">
                        Designed for all of humanity, no matter who you are or where you are.
                    </p>

                </div>
                <PillContainer/>
            </div>
            {/*<WhatWeOffer/>*/}
            {/*<HowItWorks/>*/}
        </div>
    );
};

export default AboutMentra;
