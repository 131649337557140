import React from 'react';
import tick from "../assets/Assets/Svgs/tick.svg";
import tick_white from "../assets/Assets/Svgs/tick_white.svg";

const SubscriptionPlan = () => {
    return (
        <div className="w-full flex flex-col lg:flex-row items-center justify-center gap-8 py-12">
            {/* Free Plan */}
            <div className="flex flex-col gap-2 bg-white shadow-md border border-[#71A59B] rounded-[32px] p-4 w-full lg:w-[402px] h-[600px]">

                <div className="blur flex flex-col justify-between min-h-[240px]">
                    <div className="flex items-center justify-between mb-4">
                    <span className="bg-[#D88659] text-white border-2 border-[#e6b397] font-semibold px-4 py-1 rounded-full">
                        Free Plan
                    </span>
                    </div>
                    <h3 className="text-lg font-semibold text-gray-800">Explore & Grow</h3>
                    <p className="text-sm text-gray-500 mt-1">
                        Access a selection of articles, tips, and tools to support your mental health journey.
                    </p>
                    <p className="text-2xl text-[#57867D] mt-3">Free <span className="text-lg text-gray-500 font-thin">/ per month</span></p>
                </div>
                <button className="mt-4 w-full border border-[#71A59B] text-black py-2 rounded-lg hover:bg-[#71A59B] hover:text-white">
                    Coming Soon
                </button>
                <hr className="my-4"/>
                <div className="blur">
                    <h4 className="text-lg font-medium text-gray-800">Features</h4>
                    <ul className="mt-2 space-y-2 text-gray-500 text-sm">
                        <li className="flex">
                            <img src={tick} alt="tick sign "/>
                            <span className="pl-4">Limited Audio and Text Interactions with Mentra</span>
                        </li>
                        <li className="flex">
                            <img src={tick} alt="tick sign "/>
                            <span className="pl-4">Access to Guided Journals</span>
                        </li>
                        <li className="flex">
                            <img src={tick} alt="tick sign "/>
                            <span className="pl-4">Self-Help Challenges</span>
                        </li>
                        <li className="flex">
                            <img src={tick} alt="tick sign "/>
                            <span className="pl-4">Access to the Wellness Library</span>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Essential Plan */}
            <div className="bg-[#71A59B] shadow-md border border-[#71A59B] rounded-[32px] p-4  w-full lg:w-[402px] h-[600px] relative">
                <div className="blur flex items-center justify-between mb-6 mt-1">
                    <span className="bg-[#CEF1EA] border-2 border-gray-200 text-green-700 text-sm font-semibold px-3 py-1 rounded-full">
                        Essential Plan
                    </span>
                    <span className="absolute border-2 border-gray-200 top-4 right-[-2px] bg-[#CEF1EA] text-black font-caveat text-xl px-2 py-1 rounded">
                        Recommended
                    </span>
                </div>
                <div className="blur">
                    <h3 className="text-lg font-semibold text-gray-50">Unlimited Support</h3>
                    <p className="text-[#FFFFFFCC] text-sm mt-2 py-2">
                        For those ready to take control of their mental health with full access to everything Mentra has to offer.
                    </p>
                    <p className="text-2xl font-bold text-white mt-3 tracking-wide">29 AED <span className="text-lg font-thin">/ per month</span></p>
                </div>
                <button className="mt-4 mb-2 w-full bg-white text-black py-2 rounded-lg hover:bg-[#cef1ea]">
                    Coming Soon
                </button>
                <hr className="my-6 mb-4"/>
                <div className="blur">
                    <h4 className="text-lg font-medium text-gray-50">Features</h4>
                    <ul className="mt-2 space-y-2 text-[#FFFFFFCC] text-sm">
                        <li className="flex">
                            <img src={tick_white} alt="tick sign "/>
                            <span className="pl-4">Unlimited Audio and Text Interactions with Mentra</span>
                        </li>
                        <li className="flex">
                            <img src={tick_white} alt="tick sign "/>
                            <span className="pl-4">Exclusive Guided Journals</span>
                        </li>
                        <li className="flex">
                            <img src={tick_white} alt="tick sign "/>
                            <span className="pl-4">Advanced Self-Help Challenges</span>
                        </li>
                        <li className="flex">
                            <img src={tick_white} alt="tick sign "/>
                            <span className="pl-4">Comprehensive Mental Health Insights</span>
                        </li>
                        <li className="flex">
                            <img src={tick_white} alt="tick sign "/>
                            <span className="pl-4">Detailed Interaction Summaries</span>
                        </li>
                        <li className="flex">
                            <img src={tick_white} alt="tick sign "/>
                            <span className="pl-4">Priority Access to New Features</span>
                        </li>
                        <li className="flex">
                            <img src={tick_white} alt="tick sign "/>
                            <span className="pl-4">Dedicated Wellness Library</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPlan;