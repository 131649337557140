import React from "react";
import background from "@/assets/Assets/Svgs/bgjourney.svg";
import circle1 from "@/assets/Assets/Pngs/circle-1.png";
import circle2 from "@/assets/Assets/Pngs/circle-2.png";
import circle3 from "@/assets/Assets/Pngs/circle-3.png";
import circle4 from "@/assets/Assets/Pngs/circle-4.png";
import circle5 from "@/assets/Assets/Pngs/circle-5.png";

import _1to2 from "@/assets/Assets/Pngs/1to2.png";
import _2to3 from "@/assets/Assets/Pngs/2to3.png";
import _3to4 from "@/assets/Assets/Pngs/3to4.png";
import _4to5 from "@/assets/Assets/Pngs/4to5.png";

import mobileLine from "@/assets/Assets/Pngs/mobileLine.png";
import useInView from "./useInView";
import "./journey.css";
import './header.css'

const JourneyMentra = () => {
    const [sectionRef, sectionInView] = useInView();

    return (
        <div
            className="w-full h-auto lg:h-[800px] flex justify-center bg-repeat"
            // className="lg:w-screen h-[unset] lg:h-screen 2xl:h-screen flex justify-center bg-cover bg-no-repeat"
            style={{backgroundImage: `url(${background})`}}
        >
            <div className="min-w-[80%] 2xl:max-w-[50%] h-[unset] flex flex-col items-center gap-12">
                <div className="w-full flex flex-col items-center text-center gap-2">
                    <h1
                        className="heading philosopher"
                        // className="text-3xl md:text-4xl 2xl:text-5xl font-bold text-[#002C3C] philosopher leading-tight"
                    >
                        Your Journey With Mentra!
                    </h1>
                    {/*<p className="text-[#002C3C] tablet:text-[16px] laptop:text-[18px] font-light">*/}
                    <p className="sub_heading">
                        Mentra is here to guide you on your mental health journey.
                    </p>

                </div>

                <div className="w-full flex flex-col gap-6" ref={sectionRef}>
                    {/* Step Layout */}
                    <div className="relative w-full flex flex-col items-center gap-12 lg:flex-row">
                        {/*gap-12 justify-between*/}
                        {/* Step 1 */}
                        <div className={`z-10 flex flex-col items-center static xl:absolute lg:top-[5px] lg:left-[140px] ${sectionInView ? "animate-slideFromLeft" : ""}`}>
                            <div
                                className="bg-contain bg-no-repeat flex flex-col items-center pt-[20px] h-[220px] 2xl:h-[240px] w-[220px] 2xl:w-[240px] gap-6"
                                style={{backgroundImage: `url(${circle1})`}}
                            >
                                <div className="bg-white w-[80%] flex py-2 gap-4 rounded-[30px] overflow-hidden shadow-md">
                                    <div className="bg-[#BB7A7C] w-[25%] h-[28px] flex items-center justify-center rounded-r-[50%]">
                                        <p className="text-white font-semibold">1</p>
                                    </div>
                                    <p className="w-[75%] flex items-center philosopher">SIGN UP</p>
                                </div>
                                <p className="w-[80%] text-center text-sm">
                                    Share a little about yourself and what’s on your mind—this is the first step in your mental health journey.
                                </p>
                            </div>
                        </div>

                        {/* Arrow 1 -> 2 */}
                        <img
                            src={_1to2}
                            alt="Arrow 1 to 2"
                            className={`hidden lg:block absolute lg:left-[28%] lg:top-[150px] lg:w-[90px] xl:left-[32%] xl:top-[150px] xl:w-[90px] 2xl:left-[26%] 2xl:top-[150px] 2xl:w-[130px] -translate-y-1/2 ${sectionInView ? "animate-slideFromBottom" : ""}`}
                            // className={`absolute top-[100px] left-[32.5%] 2xl:left-[30%] w-[90px] 2xl:w-[110px] hidden tablet:block -translate-y-1/2 ${sectionInView ? "animate-slideFromBottom" : ""}`}
                        />

                        {/* Mobile Arrow */}
                        <img
                            src={mobileLine}
                            alt="Mobile Line"
                            className={`block tablet:hidden ${sectionInView ? "animate-slideFromBottom" : ""}`}
                        />

                        {/* Step 2 */}
                        <div className={`z-10 flex flex-col items-center static lg:absolute lg:top-[100px] 2xl:top-[10px] lg:left-[40%] ${sectionInView ? "animate-slideFromRight" : ""}`}>
                            <div
                                className="bg-contain bg-no-repeat flex flex-col items-center pt-[16px] h-[220px] 2xl:h-[240px] w-[220px] 2xl:w-[240px] gap-6"
                                style={{backgroundImage: `url(${circle2})`}}
                            >
                                <div className="bg-white w-[80%] flex py-2 gap-4 rounded-[30px] overflow-hidden shadow-md">
                                    <div className="bg-[#869954] w-[25%] h-[28px] flex items-center justify-center rounded-r-[50%]">
                                        <p className="text-white font-semibold">2</p>
                                    </div>
                                    <p className="w-[75%] flex items-center philosopher">TALK TO MENTRA</p>
                                </div>
                                <p className="w-[80%] text-center text-sm">
                                    Share what’s on your mind—this is the second step in your journey.
                                </p>
                            </div>
                        </div>

                        {/* Arrow 2 -> 3 */}
                        <img
                            src={_2to3}
                            alt="Arrow 2 to 3"

                            // className={`absolute top-[110px] left-[59%] 2xl:left-[58%] w-[100px] 2xl:w-[120px] hidden tablet:block -translate-y-1/2 ${sectionInView ? "animate-slideFromTop" : ""}`}
                            className={`hidden lg:block absolute lg:left-[65%] lg:top-[150px] lg:w-[80px] xl:left-[59%] xl:top-[150px] xl:w-[90px] 2xl:left-[60%] 2xl:top-[150px] 2xl:w-[130px] -translate-y-1/2 ${sectionInView ? "animate-slideFromTop" : ""}`}
                        />

                        {/* Mobile Arrow */}
                        <img
                            src={mobileLine}
                            alt="Mobile Line"
                            className={`block tablet:hidden ${sectionInView ? "animate-slideFromTop" : ""}`}
                        />

                        {/* Step 3 */}
                        <div
                            className={`z-10 flex flex-col items-center static lg:absolute lg:top-[-50px] lg:right-[0px] xl:top-[5px] xl:right-[140px] ${sectionInView ? "animate-slideFromLeft" : ""}`}>
                            <div
                                className="bg-contain bg-no-repeat flex flex-col items-center pt-[16px] h-[220px] 2xl:h-[240px] w-[220px] 2xl:w-[240px] gap-6"
                                style={{backgroundImage: `url(${circle3})`}}
                            >
                                <div className="bg-white w-[80%] flex py-2 gap-4 rounded-[30px] overflow-hidden shadow-md">
                                    <div className="bg-[#B98E76] w-[25%] h-[28px] flex items-center justify-center rounded-r-[50%]">
                                        <p className="text-white font-semibold">3</p>
                                    </div>
                                    <p className="w-[75%] flex items-center philosopher">TAKE ACTION</p>
                                </div>
                                <p className="w-[80%] text-center text-sm">
                                    Start taking actionable steps to improve your mental health journey.
                                </p>
                            </div>
                        </div>

                        {/* Arrow 3 -> 4 */}
                        <img
                            src={_3to4}
                            alt="Arrow 1 to 2"
                            className={`absolute top-[280px] 2xl:top-[280px] right-[20%] w-[60px] 2xl:w-[80px] hidden tablet:block -translate-y-1/2 ${sectionInView ? "animate-slideFromBottom" : ""}`}
                        />

                        {/* Mobile Arrow */}
                        <img
                            src={mobileLine}
                            alt="Mobile Line"
                            className={`block tablet:hidden ${sectionInView ? "animate-slideFromBottom" : ""}`}
                        />
                    </div>

                    <div className="relative w-full flex flex-col-reverse gap-8 justify-around items-center mt-10 lg:flex-row">
                        {/* Step 4 */}
                        <div
                            className={`z-10 flex flex-col items-center relative lg:absolute lg:top-[50px] lg:left-[18%] xl:top-[300px] xl:left-[25%] ${sectionInView ? "animate-slideFromRight" : ""}`}>
                            <div
                                className="bg-contain bg-no-repeat flex flex-col items-center pt-[16px] h-[220px] 2xl:h-[240px] w-[220px] 2xl:w-[240px] gap-6"
                                style={{backgroundImage: `url(${circle4})`}}
                            >
                                <div className="bg-white w-[80%] flex py-2 gap-4 rounded-[30px] overflow-hidden shadow-md">
                                    <div className="bg-[#5E8F86] w-[25%] h-[28px] flex items-center justify-center rounded-r-[50%]">
                                        <p className="text-white font-semibold">5</p>
                                    </div>
                                    <p className="w-[100%] flex items-center philosopher">GROW AND THRIVE</p>
                                </div>
                                <p className="w-[80%] text-center text-sm">
                                    Continue to grow and thrive throughout your mental health journey.
                                </p>
                            </div>
                        </div>

                        {/* Arrow 4 -> 5 */}
                        <img
                            src={_4to5}
                            alt="Arrow 3 to 4"
                            className={`hidden lg:block absolute lg:left-[45%] lg:top-[200px] lg:w-[190px] xl:left-[45%] xl:top-[410px] xl:w-[190px] 2xl:left-[45%] 2xl:top-[410px] 2xl:w-[200px] -translate-y-1/2 ${sectionInView ? "animate-slideFromTop" : ""}`}
                        />

                        {/* Mobile Arrow */}
                        <img
                            src={mobileLine}
                            alt="Mobile Line"
                            className={`block tablet:hidden ${sectionInView ? "animate-slideFromTop" : ""}`}
                        />

                        {/* Step 5 */}
                        <div
                            className={`z-10 flex flex-col items-center static lg:absolute lg:top-[100px] lg:right-[20%] xl:top-[270px] xl:right-[25%] ${sectionInView ? "animate-slideFromLeft" : ""}`}>
                            <div
                                className="bg-contain bg-no-repeat flex flex-col items-center pt-[16px] h-[220px] 2xl:h-[240px] w-[220px] 2xl:w-[240px] gap-6"
                                style={{backgroundImage: `url(${circle5})`}}
                            >
                                <div className="bg-white w-[80%] flex py-2 gap-4 rounded-[30px] overflow-hidden shadow-md">
                                    <div className="bg-[#C88791] w-[25%] h-[28px] flex items-center justify-center rounded-r-[50%]">
                                        <p className="text-white font-semibold">4</p>
                                    </div>
                                    <p className="w-[75%] flex items-center philosopher">CONNECT WITH A THERAPIST</p>
                                </div>
                                <p className="w-[80%] text-center text-sm">
                                    Find a therapist who matches your needs and start your healing process.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JourneyMentra;