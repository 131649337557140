import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import "./signup.css";
import profileIcon from "../assets/Assets/Svgs/profileIcon.svg";
import eyeopen from "../assets/Assets/Images/eyeopen.png";
import eyeclose from "../assets/Assets/Images/eyeclose.png";
import Header from "../Components/Auth/Header";
import Title from "../Components/Title";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "@/App/components/hooks/useForm";
import { isEmail, isNotEmpty } from "@/App/utils/formvalidation";
import { selectCurrentToken, setCredentials } from "@/App/services/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "sonner"; // Make sure to import toast
import { useLoginMutation } from "@/App/services/authApiSlice";
import { FormLoader } from "@/Components/loader";
import { handleError } from "@/App/utils/renderErrors";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectCurrentToken);

  const {
    hasError: emailHasError,
    inputBlurHandler: emailBlurHandler,
    value: emailValue,
    valueChangeHandler: emailChangeHandler,
    reset: resetEmail,
    isValid: emailIsValid,
    errorMessage: emailErrorMessage,
  } = useForm(isEmail);

  const {
    hasError: passwordHasError,
    inputBlurHandler: passwordBlurHandler,
    value: passwordValue,
    valueChangeHandler: passwordChangeHandler,
    reset: resetPassword,
    isValid: passwordIsValid,
    errorMessage: passwordErrorMessage,
  } = useForm(isNotEmpty);

  const [login, { isLoading }] = useLoginMutation();
  const [formError, setFormError] = useState(null); // Track global form error

  useEffect(() => {
    token ? navigate("/dashboard/home") : '';
  }, [token, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [show, setShow] = useState(false);
  function handleShow() {
    setShow(!show);
  }

  // let formIsValid = emailIsValid && passwordIsValid;

  const submitHandler = async (event) => {
    event.preventDefault();
    setFormError(null);

    // Validate fields
    if (!emailIsValid && !passwordIsValid) {
      toast.error("Please fill in both the email and password fields.");
      setFormError("Please correct the errors above.");
      return;
    }

    try {
      const userData = await login({
        email: emailValue,
        password: passwordValue,
      }).unwrap();

      dispatch(
          setCredentials({
            user: userData?.data?.user,
            accessToken: userData?.data?.token,
          })
      );
      toast.success("Logged in successfully!");
      navigate("/dashboard/home", { replace: true });
    } catch (error) {
      const errorMessage = handleError(error);
      toast.error(errorMessage);
    }

    // Reset fields after login attempt
    resetEmail();
    resetPassword();
  };



  return (
      <div className="h-[100%] log-input bg-cover bg-signup min-h-screen flex flex-col bg-[#FFFDF2] relative px-[5%] py-[1%] pb-6 lg:pb-2 max-[767px]:py-[5%] max-[767px]:min-h-[100vh]">
        <Header />
        <Title title={"Login"} />
        <form
            onSubmit={submitHandler}  // Ensure form is handling the submit event correctly
            style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
            className="laptop:mt-[2.5%] max-[767px]:mt-[14%] tablet:mt-[10vh] flex flex-col mt-3 items-center shadow-lg bg-white pb-8 tablet:pb-16 laptop:pb-8 rounded-[20px] z-10 max-[767px]:w-[100%] max-[767px]:h-[70%] max-[1520px]:mb-[5%] tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px] tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
        >
          <div className="pt-[8%] text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
            <img alt="profile" src={profileIcon} />
            <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">Login to your account</h1>
            <p>Enter your details to login.</p>
          </div>

          {/* Global Error Message */}
          {/*{formError && (*/}
          {/*    <p className="text-red-700 text-center mb-4">{formError}</p>*/}
          {/*)}*/}

          <div className="max-[767px]:w-[90%] mt-4">
            {/* Email Input */}
            <div className="bg-[#F9F7F3] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[767px]:w-[100%] max-[767px]:rounded-[10px]">
              <p className="text-[15px] font-semibold text-[#191B1E]">Email Address</p>
              <input
                  className="bg-[#F9F7F3] text-[15px] font-semibold w-[100%] h-[30px] max-[767px]:text-[14px] max-[767px]:w-[100%]"
                  placeholder="Enter email"
                  type="email"
                  value={emailValue}
                  onChange={emailChangeHandler}
                  onBlur={emailBlurHandler}
              />
            </div>
            <p className={`text-red-700 w-full tracking-wider text-left my-1 ${emailHasError ? "block" : "hidden"}`}>
              {emailErrorMessage || "Please enter a valid email!"}
            </p>

            {/* Password Input */}
            <div className="bg-[#F9F7F3] mt-[3%] w-[450px] text-[13px] py-[3%] px-[5%] rounded-[30px] max-[767px]:w-[100%] max-[767px]:rounded-[10px]">
              <p className="text-[15px] font-semibold text-[#191B1E]">Password</p>
              <div className="flex items-center relative">
                <input
                    className="bg-[#F9F7F3] text-[15px] font-semibold w-[85%] h-[30px]"
                    placeholder="********"
                    type={show ? "text" : "password"}
                    value={passwordValue}
                    onChange={passwordChangeHandler}
                    onBlur={passwordBlurHandler}
                />
                <button
                    type="button"
                    onClick={handleShow}
                    className="absolute top-1/2 right-3 transform -translate-y-1/2"
                >
                  <img className="w-[20px] tablet:w-[23px]" src={show ? eyeopen : eyeclose} alt="show password" />
                </button>
              </div>
            </div>
            <p className={`text-red-700 w-full tracking-wider text-left my-1 ${passwordHasError ? "block" : "hidden"}`}>
              {passwordErrorMessage || "Enter a valid password!"}
            </p>

            {/* Forgot Password Link */}
            <div className="my-4 text-right text-[14px] underline px-4">
              <Link to="/forgotPasscode">Forgot password?</Link>
            </div>

            {/* Submit Button */}
            <div>
              <button
                  type="submit"
                  className="bg-[#1A6A73] text-center w-[100%] py-[4%] text-[white] rounded-[30px] cursor-pointer"
                  // disabled={!formIsValid || isLoading}
              >
                {isLoading ? <FormLoader /> : 'Login'}
              </button>
            </div>
          </div>
        </form>
      </div>
  );
};

export default Login;
