import React from "react";
import { useState, useEffect } from "react";
import {toast} from "sonner";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
import cloud from "../../assets/Assets/Images/cloud.png";
import pngIcon from "../../assets/Assets/Images/png-icon.png";
import pdfIcon from "../../assets/Assets/Images/pdf-icon.png";
import jpgIcon from "../../assets/Assets/Images/jpg-icon.png";
import docIcon from "../../assets/Assets/Images/doc-icon.png";
import docxIcon from "../../assets/Assets/Images/docx-icon.png";
import deleteIcon from "../../assets/Assets/Images/delete-icon.png";
import { useSignUpContext } from "./SignUpContext";

const MAX_FILE_SIZE = 50 * 1024 * 1024;

function SignUpCredentials({ setProgress, setStep }) {
  useEffect(() => {
    setProgress(11); // Set progress on component load
  }, [setProgress]);

  const { setSignupData, signupData } = useSignUpContext();
  const [files, setFiles] = useState([...signupData.credentials]);
  const [isFilled, setIsFilled] = useState(true);


  const getFileIcon = (fileName) => {
    if (fileName.endsWith(".png")) return pngIcon;
    if (fileName.endsWith(".pdf")) return pdfIcon;
    if (fileName.endsWith(".doc")) return docIcon;
    if (fileName.endsWith(".docx")) return docxIcon;
    return jpgIcon;
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files); // Handle multiple files

    const duplicates = selectedFiles.filter((file) =>
        files.some((f) => f.lastModified === file.lastModified)
    );

    const tooLarge = selectedFiles.filter((file) => file.size > MAX_FILE_SIZE);

    const validFiles = selectedFiles.filter(
        (file) =>
            file.size <= MAX_FILE_SIZE &&
            !files.some((f) => f.lastModified === file.lastModified)
    );


    if (duplicates.length > 0) {
      const duplicateNames = duplicates.map((file) => file.name).join(", ");
      toast.error(
          duplicates.length === 1
              ? `The file "${duplicateNames}" already uploaded.`
              : `The files "${duplicateNames}" are already uploaded.`
      );
    }

    if (tooLarge.length > 0) {
      const largeFileNames = tooLarge.map((file) => file.name).join(", ");
      toast.error(
          tooLarge.length === 1
              ? `The file "${largeFileNames}" too large.`
              : `The files "${largeFileNames}" are too large.`
      );
    }
    if (validFiles.length > 0) {
      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }
  };


  const handleDeleteFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };


  const handleSubmit = () => {
    if (files.length) {
      setSignupData((prevData) => ({
        ...prevData,
        credentials: files,
      }));
      setStep(12);
    } else {
      setIsFilled(false);
      toast.error('Please upload your credentials file');
    }
  };

  return (
      <div>
        <div
            className="flex flex-col pb-8 shadow-lg items-center h-full bg-white rounded-2xl mt-3 tablet:h-auto laptop:h-[70vh] laptop:min-h-[610px] tablet:w-[60vw] mx-auto tablet:max-w-[522px] lg:overflow-y-scroll"
            style={{scrollbarWidth: "none", msOverflowStyle: "none"}}
        >
          {/* Header Section */}
          <div className="pt-6 text-center flex flex-col items-center border-b border-gray-300 w-[90%] pb-6">
            <img alt="Profile Icon" src={profileIcon}/>
            <h1 className="text-2xl font-bold">Upload Credentials</h1>
            <p>Degree certificates, certifications, other licenses, etc.</p>
          </div>

          {/* Validation Message */}
          {!isFilled && (
              <p className="text-red-700 w-full tracking-wider text-center my-2">
                Please upload credentials!
              </p>
          )}

          {/* Upload Area */}
          <div className="border-dashed border-2 border-[#E7E7E0] w-[90%] px-[2%] pt-8 pb-6 rounded-[20px] text-center mt-8 mx-auto">
            <div className="text-center">
              <img src={cloud} alt="cloud" className="mx-auto w-[28px]"/>
              <h1 className="text-[18px] font-semibold">
                Choose a file <p className="text-[14px]">multiple uploads allowed</p>
              </h1>
              <p className="mt-[2%]">JPEG, PNG, PDF formats, up to 50 MB.</p>
            </div>
            <div className="mt-10 flex justify-center items-center flex-col w-full">
              <label
                  htmlFor="credentials"
                  className="bg-[white] m-0 cursor-pointer font-semibold shadow-md text-center px-5 py-[3%] text-[#000000] border-[1px] rounded-[30px]"
              >
                Browse Files
              </label>
              <input
                  type="file"
                  id="credentials"
                  accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                  onChange={handleFileChange}
                  className="file-input-element opacity-0"
                  multiple
              />
            </div>

          </div>

          {/* File Preview Section */}
          {files.map((file, index) => (
              <div
                  key={index}
                  className="w-[90%] py-4 px-5 mt-5 rounded-2xl border-2 border-gray-200 flex justify-between items-center"
              >
                <div className="flex items-center gap-3">
                  <img src={getFileIcon(file.name)} alt="File Icon" className="w-12"/>
                  <div className="space-y-1">
                    <p className="font-medium text-sm truncate">{file.name}</p>
                    <p className="font-normal text-xs text-gray-500">
                      {file.size / 1024 > 1024
                          ? `${(file.size / (1024 * 1024)).toFixed(2)} MB`
                          : `${Math.ceil(file.size / 1024)} KB`}
                    </p>
                  </div>
                </div>
                <button onClick={() => handleDeleteFile(file.name)}>
                  <img src={deleteIcon} alt="Delete Icon" className="w-6"/>
                </button>
              </div>
          ))}

          <button
              onClick={handleSubmit}
              className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-5 text-[white] rounded-[30px]"
          >
            Continue
          </button>
        </div>
      </div>
  );
}

export default SignUpCredentials;