// import React, {useState, useEffect} from 'react';
// import phoneaction from "@/assets/Assets/Svgs/phoneaction.svg";
// import AppStore from "@/assets/Assets/Svgs/appstorev2.svg";
// import PlayStore from "@/assets/Assets/Svgs/playstorev2.svg";
// import './skeleton.css'
//
// const LeftSlide = () => {
//     const [isLoading, setIsLoading] = useState(true);
//
//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setIsLoading(false);
//         }, 1000);
//
//         return () => clearTimeout(timer);
//     }, []);
//
//     return (
//         <div className="flex flex-col items-center justify-center gap-8">
//             {/* Top Image */}
//             {isLoading ? (
//                 <div className="skeleton w-[300px] h-[300px]"></div> // Skeleton for top image
//             ) : (
//                 <img src={phoneaction} alt="Top Image" loading="lazy"/>
//             )}
//
//             {/* Bottom Images */}
//             <div className="flex align-center justify-center gap-8">
//                 {isLoading ? (
//                     <>
//                         <div className="skeleton w-32 h-12"></div>
//                         {/* Skeleton for App Store */}
//                         <div className="skeleton w-32 h-12"></div>
//                         {/* Skeleton for Play Store */}
//                     </>
//                 ) : (
//                     <>
//                         <a href="https://apps.apple.com/ng/app/mentra/id6502996664" target="_blank" rel="noopener noreferrer">
//                             <img
//                                 loading="lazy"
//                                 src={AppStore}
//                                 alt="Bottom Image 1"
//                                 style={{maxWidth: '100%'}}
//                                 className="cursor-pointer"
//                             />
//                         </a>
//                         <a href="https://play.google.com/store/apps/details?id=com.yourmentra.mentra" target="_blank" rel="noopener noreferrer">
//                             <img
//                                 loading="lazy"
//                                 className="cursor-pointer"
//                                 src={PlayStore}
//                                 alt="Bottom Image 2"
//                                 style={{maxWidth: '100%'}}
//                             />
//                         </a>
//                     </>
//                 )}
//             </div>
//         </div>
//     );
// };
//
// export default LeftSlide;

import phoneaction from "@/assets/Assets/Svgs/phoneaction.svg";
import AppStore from "@/assets/Assets/Svgs/appstorev2.svg";
import PlayStore from "@/assets/Assets/Svgs/playstorev2.svg";

import React from 'react';

const LeftSlide = () => {
    return (
        <div className="flex flex-col items-center justify-center gap-8">
            <img
                src={phoneaction}
                alt="Top Image"
            />

            {/* Bottom Images */}
            <div className="flex align-center justify-center gap-8">
                <a href="https://apps.apple.com/ng/app/mentra/id6502996664" target="_blank" rel="noopener noreferrer">
                    <img
                        src={AppStore}
                        alt="Bottom Image 1"
                        style={{maxWidth: '100%'}}
                        className="cursor-pointer"
                    />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.yourmentra.mentra" target="_blank" rel="noopener noreferrer">
                    <img
                        className="cursor-pointer"
                        src={PlayStore}
                        alt="Bottom Image 2"
                        style={{maxWidth: '100%'}}
                    />
                </a>
            </div>
        </div>
    );
};

export default LeftSlide;
