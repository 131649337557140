import React from "react";
import PropTypes from "prop-types";
import heroFrames from "../../../src/assets/Assets/Svgs/heroFrames.svg";
import heroFlower from "../../../src/assets/Assets/Pngs/sliderFlower.png";
import './sllider.css'
import LeftSlide from "@/Components/Sliders/LeftSlide.jsx";


const SliderOne = ({dots}) => (<div className="max-w-[1250px] mx-auto">
        <div className="flex flex-col-reverse items-center gap-6 lg:items-start lg:flex-row lg:gap-12">
            <div className="max-w-[340px]">
                <LeftSlide/>
            </div>

            <div className="2xl:min-w-[804px]">

                <div className="flex justify-center">
                    {dots}
                </div>

                <div className="philosopher flex flex-col">
                    <h1 className="heading_custom mx-auto">
                        Mental Health Therapy that <strong className="bg-[#FAC084] px-2 rounded-xl">fits your life</strong>
                    </h1>
                    <img src={heroFrames} alt="Hero Frames" className="mt-6 max-w-[90%] lg:max-w-[60%] mx-auto"/>
                </div>
            </div>
        </div>

        <img
            src={heroFlower}
            alt="Hero Flower"
            className="static lg:absolute bottom-0 right-0 w-[100%] lg:w-[70%] xl:w-[53%] 2xl:w-[40%] object-contain"
            style={{
                clipPath: "inset(0 0 0 0)", maskImage: "linear-gradient(to bottom, black 50%, transparent 100%)", WebkitMaskImage: "linear-gradient(to bottom, black 50%, transparent 100%)",
            }}
        />
    </div>);

SliderOne.propTypes = {
    dots: PropTypes.node.isRequired
};


export default SliderOne;

