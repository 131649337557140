import React, {useState} from "react";
import {Link} from "react-router-dom";
import logo from "../assets/Assets/Svgs/logov2.svg";
import AppStore from "../assets/Assets/Svgs/appstorev2.svg";
import PlayStore from "../assets/Assets/Svgs/playstorev2.svg";
import facebook from "../assets/Assets/Svgs/facebookv2.svg";
import twitter from "../assets/Assets/Svgs/twitterv2.svg";
import background from "../assets/Assets/Svgs/backgroundv2.svg";
import LinkedIn from "../assets/Assets/Svgs/LinkedIn.svg";
import instagram from "../assets/Assets/Svgs/instagramv2.svg";

const Footer = () => {
    const [ndaPdf] = useState("../../src/assets/Assets/Pdfs/NDA_Therapists.pdf");
    const [contractPdf] = useState("../../src/assets/Assets/Pdfs/IndependentContractorTherapistTermsandConditions.pdf");
    return (
        <div id="footer" style={{backgroundImage: `url(${background})`}}>
            {/* Main Footer Container */}
            <div
                className="max-w-[85%] py-4 m-auto flex flex-col justify-between"
            >
                {/* Logo Section */}
                <div className="hidden lg:flex items-center justify-between gap-5 w-full mt-12 mb-16">
                    <img src={logo} alt="Mentra Logo" className="h-auto w-auto"/>
                    <hr className="hidden md:block w-full h-[0.5px] bg-gray-300"/>
                </div>

                {/* App Download & Links Section */}
                <div className="flex flex-col tablet:flex-row justify-evenly w-full gap-8">
                    {/* App Download Section */}
                    <div className="flex flex-col lg:items-start lg:flex-[3]">
                        <p className="philosopher text-2xl mb-4">
                            Download our mobile app now!
                        </p>
                        <div className="flex gap-4">
                            <a href="https://apps.apple.com/ng/app/mentra/id6502996664" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={AppStore}
                                    alt="Download on the App Store"
                                    className=""
                                />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.yourmentra.mentra" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={PlayStore}
                                    alt="Get it on Google Play"
                                    className=""
                                />
                            </a>
                        </div>
                    </div>

                    {/* Footer Links Section */}
                    {/*grid grid-cols-1 tablet:grid-cols-3 gap-6 w-full tablet:flex-[3] tablet:grid-template-columns-[1fr_2fr_1fr]*/}
                    <div className="flex flex-col gap-5 lg:flex-row lg:justify-between lg:flex-[3]">
                        <div>
                            <h4 className="text-lg font-semibold mb-2">Solutions</h4>
                            <ul>
                                <li>
                                    <a href="/mentra-for-work" className="text-gray-500 hover:underline leading-relaxed tracking-wide">
                                        Mentra for work
                                    </a>
                                </li>
                                <li>
                                    <a href="/wellness-library" className="text-gray-500 hover:underline leading-relaxed tracking-wide">
                                        Wellness Library
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4 className="text-lg font-semibold mb-2">For Therapists</h4>
                            <ul>
                                <li className="hover:underline">
                                    <Link to="/signup">
                                        <p className="text-gray-500 leading-relaxed tracking-wide">
                                            Become a Mentra Therapist
                                        </p>
                                    </Link>
                                </li>
                                <li className="hover:underline">
                                    <a
                                        href={contractPdf}
                                        target="_blank"
                                        className="text-gray-500 leading-relaxed tracking-wide"
                                        rel="noreferrer"
                                    >
                                        Independent Contractor Agreement
                                    </a>
                                </li>
                                <li>
                                    <a href={ndaPdf}
                                       target="_blank"
                                       className="text-gray-500 hover:underline leading-relaxed tracking-wide"
                                       rel="noreferrer"
                                    >
                                        Non-Disclosure Agreement
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h4 className="text-lg font-semibold mb-2">Company</h4>
                            <ul>
                                <li>
                                    <Link to="/about">
                                        <p className="text-gray-500 hover:underline leading-relaxed tracking-wide">
                                            About us
                                        </p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        <p className="text-gray-500 hover:underline leading-relaxed tracking-wide">
                                            Contact us
                                        </p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/faq">
                                        <p className="text-gray-500 hover:underline leading-relaxed tracking-wide">
                                            FAQs
                                        </p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms-and-conditions">
                                        <p className="text-gray-500 hover:underline leading-relaxed tracking-wide">
                                            Terms & Conditions
                                        </p>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">
                                        <p className="text-gray-500 hover:underline leading-relaxed tracking-wide">
                                            Privacy Policy
                                        </p>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                {/* Footer Bottom Section */}
                <div className="w-full bg-gray-300 h-[0.5px] mt-8 mb-4"></div>
                <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center w-full gap-4 lg:gap-0">
                    {/* Copyright Section */}
                    <p className="text-sm text-gray-500 w-full lg:w-auto text-left">
                        © Mentra 2024 - All rights reserved
                    </p>

                    {/* Connect with Us Section */}
                    <div className="flex flex-col md:flex-row items-start lg:items-center w-full lg:w-auto gap-4 lg:gap-2 lg:justify-end">
                        {/* Connect Text */}
                        <span className="font-semibold text-left mr-4">Connect with us</span>

                        {/* Social Logos Section */}
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 lg:flex lg:flex-row lg:gap-4">
                            <a href="https://www.instagram.com/heymentra/" target="_blank" rel="noopener noreferrer">
                                <img src={instagram} alt="Instagram" className="h-5"/>
                            </a>
                            <a href="https://www.facebook.com/yourmentra/" target="_blank" rel="noopener noreferrer">
                                <img src={facebook} alt="Facebook" className="h-5"/>
                            </a>
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                <img src={twitter} alt="Twitter/X" className="h-5"/>
                            </a>
                            <a href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">
                                <img src={LinkedIn} alt="LinkedIn" className="h-5"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;