import React, {useRef, useEffect} from "react";
import {HiChevronLeft, HiChevronRight} from "react-icons/hi";

const TherapyPlan = () => {
    const scrollContainerRef = useRef(null);

    const scrollLeft = () => {
        scrollContainerRef.current.scrollBy({
            left: -300, behavior: "smooth",
        });
    };

    const scrollRight = () => {
        scrollContainerRef.current.scrollBy({
            left: 300, behavior: "smooth",
        });
    };

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft = scrollContainerRef.current.scrollWidth;
        }
    }, []);

    return (<div className="relative">
            {/* Scroll container with horizontal scrolling */}
            <div
                ref={scrollContainerRef}
                className="flex overflow-x-auto py-6 lg:px-4"
            >
                <div className="flex flex-col md:flex-row items-center w-full m-auto gap-10 2xl:justify-center">
                    {/* Single Session Card */}
                    <div className="w-full md:w-[382px] flex-shrink-0">
                        <div
                            className="h-[376px] flex flex-col items-center justify-between bg-white border border-[#578A80] overflow-hidden rounded-[32px] p-6 text-center shadow-lg transition-transform transform lg:hover:scale-105">
                            <div className="flex flex-col items-center mt-8">
                                <div className="text-white font-semibold rounded-full py-2 px-3 bg-gradient-to-b from-[#77AFA4] to-[#619288]">
                                    1 Session
                                </div>
                                <p className="mt-4 text-[#4B4B4B] text-sm leading-relaxed">
                                    Work one-on-one with a licensed professional to address your needs, whether it’s stress, anxiety, relationships, or personal growth.
                                </p>

                                <div className="mt-6 flex items-center">
                                    <span className="text-[24px] lg:text-[32px] font-[700] text-[#578A80] px-1">
                                        249 AED
                                    </span>
                                    <span className="text-sm text-gray-500 mt-1 px-1">
                                        per session
                                    </span>
                                </div>
                            </div>
                            <a
                                href="https://onelink.to/cr4nua"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block w-full"
                            >
                                <button
                                    className="mt-auto px-8 py-3 w-full text-black text-sm font-medium rounded-lg shadow-md border border-[#578A80] hover:bg-gradient-to-b hover:from-[#619288] hover:to-[#578A80] hover:text-white transition-all duration-300">
                                    Buy Now
                                </button>
                            </a>
                        </div>
                    </div>

                    {/* 3 Sessions Card */}
                    <div className="w-full md:w-[382px] flex-shrink-0">
                        <div
                            className="h-[376px] flex flex-col items-center justify-between bg-white border border-[#578A80] overflow-hidden rounded-[32px] p-6 text-center shadow-lg transition-transform transform lg:hover:scale-105">
                            <div className="flex flex-col items-center mt-8">
                                <div className="text-white font-semibold rounded-full py-2 px-3 bg-gradient-to-b from-[#D88659] to-[#BF6841]">
                                    3 Sessions
                                </div>
                                <p className="mt-4 text-[#4B4B4B] font-bold italic text-sm leading-relaxed">
                                    Small Steps, Big Changes
                                </p>
                                <p className="mt-4 text-[#4B4B4B] text-sm leading-relaxed">
                                    Ideal for short-term goals like stress management, decision-making, or exploring a specific concern.
                                </p>
                                <div className="mt-6 flex items-center">
                                    <span className="text-[24px] lg:text-[32px] font-[700] text-[#D88659] px-1">
                                        719 AED
                                    </span>
                                    <span className="text-sm text-gray-500 mt-1 px-1">
                                        Save 31 AED (5%)
                                    </span>
                                </div>
                            </div>
                            <a
                                href="https://onelink.to/cr4nua"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block w-full"
                            >
                                <button
                                    className="mt-auto px-8 py-3 w-full text-black text-sm font-medium rounded-lg shadow-md border border-[#578A80] hover:bg-gradient-to-b hover:from-[#619288] hover:to-[#578A80] hover:text-white transition-all duration-300">
                                    Buy Now
                                </button>
                            </a>
                        </div>
                    </div>

                    {/* 5 Sessions Card */}
                    <div className="w-full md:w-[382px] flex-shrink-0">
                        <div
                            className="h-[376px] flex flex-col items-center justify-between bg-white border border-[#578A80] rounded-[32px] p-6 text-center shadow-lg transition-transform transform lg:hover:scale-105 relative"
                        >
                            {/*Recommended Ribbon */}
                            <div
                                className="absolute top-[-16px] right-0 bg-[#DFF4EF] text-[#23313B] font-caveat text-xl px-4 py-1 rounded-tl-lg rounded-br-lg shadow-sm"
                            >
                                Recommended
                            </div>

                            {/*<div*/}
                            {/*    className="absolute top-[-16px] right-0 bg-[#DFF4EF] text-[#23313B] font-caveat text-xl px-12 py-1 shadow-sm"*/}
                            {/*    style={{*/}
                            {/*        // clipPath: "polygon(0 0, 100% 0, 100% 100%, 10px 100%, 0 50%)",*/}
                            {/*        clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%, 30% 0)",*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    Recommended*/}
                            {/*</div>*/}


                            {/* Sessions Info */}
                            <div className="flex flex-col items-center mt-8">
                                <div className="text-white font-semibold rounded-full py-2 px-3 bg-[#809449]">
                                    5 Sessions
                                </div>
                                <p className="mt-4 text-[#4B4B4B] font-bold italic text-sm leading-relaxed">
                                    Stronger Foundations
                                </p>
                                <p className="mt-4 text-[#4B4B4B] text-sm leading-relaxed">
                                    Perfect for addressing deeper issues or building new mental health habits over time.
                                </p>

                                {/* Pricing Info */}
                                <div className="mt-6 flex items-center">
                                        <span className="text-[24px] lg:text-[32px] font-[700] text-[#809449] px-1">
                                          1,119 AED
                                        </span>
                                    <span className="text-sm text-gray-500 mt-1 px-1">
                                          Save 51 AED (10%)
                                    </span>
                                </div>
                            </div>

                            {/* Buy Now Button */}
                            <a
                                href="https://onelink.to/cr4nua"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block w-full"
                            >
                                <button
                                    className="mt-auto px-8 py-3 w-full text-black text-sm font-medium rounded-lg shadow-md border border-[#578A80] hover:bg-gradient-to-b hover:from-[#619288] hover:to-[#578A80] hover:text-white transition-all duration-300">
                                    Buy Now
                                </button>
                            </a>
                        </div>
                    </div>

                    {/* 10 Sessions Card */}
                    <div className="w-full md:w-[382px] flex-shrink-0">
                        <div
                            className="h-[376px] flex flex-col items-center justify-between bg-white border border-[#578A80] overflow-hidden rounded-[32px] p-6 text-center shadow-lg transition-transform transform lg:hover:scale-105">
                            <div className="flex flex-col items-center mt-8">
                                <div className="text-white font-semibold rounded-full py-2 px-3 bg-[#b36370]">
                                    10 Sessions
                                </div>
                                <p className="mt-4 text-[#4B4B4B] font-bold italic text-sm leading-relaxed">
                                    Transformational Growth
                                </p>
                                <p className="mt-4 text-[#4B4B4B] text-sm leading-relaxed">
                                    Best for long-term support and personal growth through
                                    significant milestones.
                                </p>
                                <div className="mt-6 flex items-center">
                                    <span className="text-[24px] lg:text-[32px] font-[700] text-[#b36370] px-1">
                                         2,199 AED
                                    </span>
                                    <span className="text-sm text-gray-500 mt-1 px-1">
                                        Save 51 AED (12%)
                                    </span>
                                </div>
                            </div>
                            <a
                                href="https://onelink.to/cr4nua"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block w-full"
                            >
                                <button
                                    className="mt-auto px-8 py-3 w-full text-black text-sm font-medium rounded-lg shadow-md border border-[#578A80] hover:bg-gradient-to-b hover:from-[#619288] hover:to-[#578A80] hover:text-white transition-all duration-300">
                                    Buy Now
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        {/* Left Arrow Button */}
        <button
            onClick={scrollLeft}
            className="hidden md:block lg:block xl:block 2xl:hidden absolute top-1/2 left-[-80px] md:left-[-50px] transform -translate-y-1/2 text-white bg-[#84a8a1] p-3 rounded-full shadow-lg"
        >
            <HiChevronLeft className="text-xl"/>
        </button>

        {/* Right Arrow Button */}
        <button
            onClick={scrollRight}
            className="hidden md:block lg:block xl:block 2xl:hidden absolute top-1/2 right-[-80px] md:right-[-50px] transform -translate-y-1/2 text-white bg-[#84a8a1] p-3 rounded-full shadow-lg"
            >
                <HiChevronRight className="text-xl"/>
            </button>
        </div>);
};

export default TherapyPlan;