import React from "react";
import different from "@/assets/Assets/Svgs/different.svg";
import LeftSlide from "@/Components/Sliders/LeftSlide.jsx";
import PropTypes from "prop-types";


const SliderThree = ({dots}) => (
    <div
        className="max-w-[1250px] flex flex-col-reverse gap-6 items-center lg:items-start lg:flex-row lg:gap-12"
    >
        <div className="max-w-[340px]">
            <LeftSlide/>
        </div>

        <div className="2xl:min-w-[804px]">

            <div className="flex justify-center">
                {dots}
            </div>

            <div className="philosopher flex flex-col items-center ">
                <h1 className="heading_custom">
                    That’s why we created Mentra —<strong className="bg-[#ffa3a4] px-2 rounded-xl">to meet you</strong> <strong className="bg-[#c1d392] px-2 rounded-xl">where you are</strong>
                </h1>
                <img src={different} alt="Hero Frames" className="mt-6 "/>
            </div>

        </div>

    </div>
);

SliderThree.propTypes = {
    dots: PropTypes.node.isRequired
};

export default SliderThree;