import React, {useState, useEffect} from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
import cloud from "../../assets/Assets/Images/cloud.png";
import png from "../../assets/Assets/Images/png-icon.png";
import pdf from "../../assets/Assets/Images/pdf-icon.png";
import jpg from "../../assets/Assets/Images/jpg-icon.png";
import doc from "../../assets/Assets/Images/doc-icon.png";
import docx from "../../assets/Assets/Images/docx-icon.png";
import deleteIcon from "../../assets/Assets/Images/delete-icon.png";
import {useSignUpContext} from "./SignUpContext";
import {toast} from "sonner";

const SignupFormNine = ({setProgress, setStep}) => {


    useEffect(() => {
        setProgress(10);
    }, [setProgress]);

    const {setSignupData, signupData} = useSignUpContext();
    const [files, setFiles] = useState([...signupData.licence]);
    const [filled, setFilled] = useState(true);

    const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB

    const getIcon = (type) => {
        switch (type) {
            case "image/png":
                return png;
            case "application/pdf":
                return pdf;
            case "application/msword":
                return doc;
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return docx;
            case "image/jpeg":
                return jpg;
            default:
                return jpg;
        }
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);

        const duplicates = selectedFiles.filter((file) =>
            files.some((f) => f.lastModified === file.lastModified)
        );

        const tooLarge = selectedFiles.filter((file) => file.size > MAX_FILE_SIZE);

        const validFiles = selectedFiles.filter(
            (file) =>
                file.size <= MAX_FILE_SIZE &&
                !files.some((f) => f.lastModified === file.lastModified)
        );


        if (duplicates.length > 0) {
            const duplicateNames = duplicates.map((file) => file.name).join(", ");
            toast.error(
                duplicates.length === 1
                    ? `The file "${duplicateNames}" already uploaded.`
                    : `The files "${duplicateNames}" are already uploaded.`
            );
        }

        if (tooLarge.length > 0) {
            const largeFileNames = tooLarge.map((file) => file.name).join(", ");
            toast.error(
                tooLarge.length === 1
                    ? `The file "${largeFileNames}" too large.`
                    : `The files "${largeFileNames}" are too large.`
            );
        }
        if (validFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...validFiles]);
        }
    };


    const handleDeleteFile = (lastModified) => {
        const newFiles = files.filter((f) => f.lastModified !== lastModified);
        setFiles(newFiles);
    };

    const handleClick = () => {
        if (files.length > 0) {
            setSignupData((prevData) => ({
                ...prevData,
                licence: files,
            }));
            setStep(11);
        } else {
            toast.error('Please upload your license file');
            setFilled(false);
        }
    };

    useEffect(() => {
        setFilled(files.length > 0);
    }, [files]);

    return (
        <div>
            <div
                style={{scrollbarWidth: "none", msOverflowStyle: "none"}}
                className="flex flex-col pb-8 shadow-lg items-center h-[100%] bg-white rounded-[20px] mt-3 z-1 tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px] tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
            >
                {/* Header Section */}
                <div className="pt-[9%] lg:pt-6 text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
                    <img alt="" src={profileIcon}/>
                    <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
                        Upload License
                    </h1>
                    <p>Upload a valid practice licence.</p>
                </div>

                {/* Validation Error */}
                <p
                    className={`text-red-700 w-full ${
                        filled ? "opacity-0" : "opacity-1"
                    } tracking-wider text-center my-2`}
                >
                    Please upload a license!
                </p>

                {/* Upload Section */}
                <div className="border-dashed border-2 border-[#E7E7E0] w-[90%] px-[2%] pt-8 pb-6 rounded-[20px] text-center mt-2 mx-auto">
                    <div className="text-center">
                        <img src={cloud} alt="cloud" className="mx-auto w-[28px]"/>
                        <h1 className="text-[18px] font-semibold">
                            Choose a file <p className="text-[14px]">multiple uploads allowed</p>
                        </h1>
                        <p className="mt-[2%]">JPEG, PNG, PDF formats, up to 50 MB.</p>
                    </div>
                    <div className="mt-10 flex justify-center items-center flex-col w-full">
                        <label
                            htmlFor="credentials"
                            className="bg-[white] m-0 cursor-pointer font-semibold shadow-md text-center px-5 py-[3%] text-[#000000] border-[1px] rounded-[30px]"
                        >
                            Browse Files
                        </label>
                        <input
                            type="file"
                            id="credentials"
                            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                            onChange={handleFileChange}
                            className="file-input-element opacity-0"
                            multiple
                        />
                    </div>

                </div>

                {/* File Preview Section */}
                {files.map((file) => (
                    <div
                        key={file.lastModified}
                        className="w-[90%] py-4 px-5 mt-5 rounded-[20px] border-2 border-[#E7E7E0] flex justify-between items-center"
                    >
                        <div className="flex justify-start items-center max-w-[90%] gap-3">
                            <img src={getIcon(file.type)} alt="icon" className="w-[45px]"/>
                            <div className="space-y-[2px] overflow-hidden">
                                <p className="font-medium text-[14px]">{file.name}</p>
                                <p className="font-normal text-[12px] text-[#333333]">
                                    {file.size / 1024 > 1024
                                        ? `${(file.size / (1024 * 1024)).toFixed(2)} MB`
                                        : `${Math.ceil(file.size / 1024)} KB`}
                                </p>
                            </div>
                        </div>
                        <button onClick={() => handleDeleteFile(file.lastModified)}>
                            <img src={deleteIcon} className="w-[24px]" alt="delete"/>
                        </button>
                    </div>
                ))}

                <div className="w-full">
                    <button
                        onClick={handleClick}
                        className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-5 text-[white] rounded-[30px]"
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SignupFormNine;