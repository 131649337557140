import React, { useEffect, useRef } from 'react';
import background from "@/assets/Assets/Svgs/backgroundv2.svg";
import female from '../assets/Assets/Pngs/female.png'
import male from '../assets/Assets/Pngs/male.png'
import "./UserComments.css";
import './header.css'


// eslint-disable-next-line react/prop-types
const UserCommentList = ({ users, scrollRef }) => {
    return (
        <div className="fade-container">
            <div className="fade-wrapper">
                <div ref={scrollRef} className="fade-content">
                    <div className="flex flex-col gap-4">
                        {users?.map((user) => (
                            <div
                                key={user.id}
                                className="bg-[#fef9f9] flex flex-col p-10 gap-6 border border-[#E1C9CB] rounded-ss-[80px] rounded-es-[28px] rounded-se-[28px] rounded-ee-[80px] max-w-[320px]"
                            >
                                <p className="text-[#002C3C] italic lg:text-[16px] font-light max-w-[250px] 2xl:max-w-full">
                                    {user.comment}
                                </p>
                                <div className="flex gap-2 items-center">
                                    <img
                                        src={user.image}
                                        alt="User Image"
                                        className="w-12 h-12 rounded-full"
                                    />
                                    <div className="flex flex-col">
                                        <p className="blur">Anonymous</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};


const users = [
    {
        id: 1,
        name: "Ali Al Mansoori",
        title: "Clinical Psychologist",
        image: male,
        comment: `"I didn’t know how to ask for help, but Mentra made it easy. The AI check-in felt surprisingly comforting, and booking a therapist was simple. It’s been a game-changer for me"`
    },
    {
        id: 3,
        name: "Omar Al Falasi",
        title: "Mental Health Expert",
        image: male,
        comment: `"I thought therapy was out of my budget until I found Mentra. It’s affordable, but more importantly, it works. I finally feel like I’m making progress."`
    },
    {
        id: 2,
        name: "Sara Al Habtoor",
        title: "Therapist",
        image: female,
        comment: `"had a really tough night, and Mentra’s AI support was there when no one else was. Just having something to talk to helped more than I expected."`
    },
    {
        id: 5,
        name: "Hassan Al Nuaimi",
        title: "Counselor",
        image: male,
        comment: `"I was scared to open up about how I was feeling, but Mentra made me feel safe. It’s the first time I’ve felt truly heard without being judged."`
    },
    {
        id: 4,
        name: "Aisha Al Mazrouei",
        title: "Wellness Coach",
        image: female,
        comment: `"I travel a lot for work, so traditional therapy never worked for me. With Mentra, I can talk to my therapist from anywhere. It’s flexible and reliable."`
    }
];
const UserComments = () => {
    const leftScrollRef = useRef(null);
    const rightScrollRef = useRef(null);

    // Function to handle smooth scrolling
    const startScroll = (container, direction = 'up') => {
        let scrollPosition = container.scrollTop;

        const scroll = () => {
            const containerHeight = container.offsetHeight;
            const scrollHeight = container.scrollHeight;

            if (direction === 'up') {
                // Scroll up
                if (scrollPosition >= scrollHeight - containerHeight) {
                    scrollPosition = 0;
                } else {
                    scrollPosition += 1;
                }
            } else {
                // Scroll down
                if (scrollPosition <= 0) {
                    scrollPosition = scrollHeight - containerHeight;
                } else {
                    scrollPosition -= 1;
                }
            }

            container.scrollTop = scrollPosition;
            requestAnimationFrame(scroll);
        };

        requestAnimationFrame(scroll);
    };

    useEffect(() => {
        if (leftScrollRef.current) {
            startScroll(leftScrollRef.current, 'up');
        }
        if (rightScrollRef.current) {
            startScroll(rightScrollRef.current, 'down');
        }
    }, []);

    return (
        <div
            className="w-full mt-12"
            style={{ backgroundImage: `url(${background})` }}
        >
            <div className="max-w-[85%] 2xl:max-w-[70%] py-4 m-auto flex flex-col items-center lg:flex-row gap-12 md:gap-20">
                {/* Text Container */}
                <div className="w-full lg:w-[30%] flex flex-col gap-4 md:overflow-hidden relative justify-center items-center">
                    <div className="text-center lg:text-left">
                        <h1 className="heading philosopher">
                            What Users<br /> Say <span className="bg-[#FAD9B6] px-2 rounded-[14px]"> about us</span>
                        </h1>
                    </div>
                </div>

                {/* User comments container */}
                <div className="w-full lg:w-[70%] flex flex-row justify-center gap-12">
                    {/* Left Column Infinite Scroll (upwards) */}
                    <UserCommentList users={users} scrollRef={leftScrollRef} />

                    {/* Right Column Infinite Scroll (downwards) */}
                    <div className="hidden md:block">
                        <UserCommentList users={users.slice(0).reverse()} scrollRef={rightScrollRef} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserComments;

