import React from 'react';
import {useState, useEffect} from "react";
import ReactGA from "react-ga4";
import background from "../assets/Assets/Svgs/backgroundv2.svg";
import AboutMentra from "../Components/HomeComponents/AboutMentra";
// import JoinMentra from "../Components/JoinMentra";
// import Testimonials from "../Components/Utilities/Testimonials";
import Footer from "../Components/Footer";
import "../Components/SignupForms/style.css";
import {AnimatePresence} from "framer-motion";
import Title from "../Components/Title";
import SliderOne from "@/Components/Sliders/SliderOne.jsx";
import SliderTwo from "@/Components/Sliders/SliderTwo.jsx";
import SliderThree from "@/Components/Sliders/SliderThree.jsx";
import Waitlist from "../Components/Waitlist";

import SupportedBy from "@/Components/SupportedBy.jsx";
import VideoSection from "@/Components/VideoSection.jsx";
import Plans from "@/Components/Plans.jsx";
import Faqs from "@/Components/Faqs.jsx";
import JourneyMentra from "@/Components/JourneyMentra.jsx";
import UserComments from "@/Components/UserComments.jsx";


const HomePage = () => {
    useEffect(() => {
        ReactGA.send({hitType: "pageview", page: window.location.pathname});
    }, []);

    const [isModalOpen, setModalOpen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);


    const renderDots = () => {
        return (
            <div className="flex gap-2">
                {[0, 1, 2].map((index) => (
                    <button
                        key={index}
                        onClick={() => goToSlide(index)}
                        className={`w-3 h-3 mt-4 mb-4 rounded-full transition-colors ${
                            currentSlide === index ? "bg-[#5E8F86]" : "bg-[#CDEAE6]"
                        }`}
                    />
                ))}
            </div>
        );
    };


    const sliders = [
        <SliderOne key="slider1" dots={renderDots()}/>,
        <SliderTwo key="slider2" dots={renderDots()}/>,
        <SliderThree key="slider3" dots={renderDots()}/>,
    ];


    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % sliders.length);
        }, 5000);

        return () => clearInterval(intervalId);
    }, [sliders.length]);

    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    return (
        <div>
            <Title title={"Home"}/>

            <AnimatePresence>
                {isModalOpen && <Waitlist setModalOpen={setModalOpen}/>}
            </AnimatePresence>

            <div className="mt-[70px] md:mt-[100px] lg:mt-[150px] relative flex items-center justify-center min-h-[50vh]">
                {/* Current Slide */}
                {sliders[currentSlide]}
            </div>

            {/*<VideoSection/>*/}
            <SupportedBy/>
            <UserComments/>
            <AboutMentra/>
            <JourneyMentra/>
            <Plans/>
            <Faqs/>
            <Footer/>
        </div>
    );
};

export default HomePage;


// const HomePage = () => {
//     useEffect(() => {
//         ReactGA.send({hitType: "pageview", page: window.location.pathname});
//     }, []);
//
//     // const [isSmallScreen, setIsSmallScreen] = useState(false);
//     const [isModalOpen, setModalOpen] = useState(false);
//     const openModal = () => {
//         setModalOpen(true);
//     };
//
//     // useEffect(() => {
//     //   const handleResize = () => {
//     //     setIsSmallScreen(window.innerWidth < 768);
//     //   };
//
//     //   handleResize();
//
//     //   window.addEventListener("resize", handleResize);
//
//     //   return () => {
//     //     window.removeEventListener("resize", handleResize);
//     //   };
//     // }, []);
//
//     return (
//         <div>
//             <Title title={"Home"}/>
//             <div
//                 className="w-[100%] laptop:min-h-[762px] laptop:h-[100vh] laptop:grid laptop:place-items-center laptop:max-h-[940px] overflow-hidden"
//                 style={{
//                     backgroundImage: `url(${background})`,
//                 }}
//             >
//                 {/* Hero Section */}
//                 <div className="flex space-x-4">
//                     {/* Left Side */}
//                     <div className="flex justify-center items-center w-1/3">
//                         <img
//                             src={leftHero}
//                             alt="Left Hero"
//                             className="max-w-full h-auto"
//                         />
//                     </div>
//
//                     {/* Right Side */}
//                     <div className="flex flex-col w-2/3 gap-6">
//                         <div className="flex flex-col gap-6 items-center mt-6">
//                             <img
//                                 src={dot1}
//                                 alt="dot1"
//                                 className="max-w-full h-auto w-[80px] "
//                             />
//                             <h1 className="text-6xl font-bold text-[#002C3C] text-center"
//                                 style={{fontFamily: 'Philosopher, sans-serif'}}>
//                                 Mental Health Therapy that <br/> <span className="bg-[#fac084] px-4 rounded-2xl">fits your life</span>
//                             </h1>
//                             <img
//                                 src={heroFrames}
//                                 alt="heroFrames"
//                                 className="max-w-full h-auto"
//                             />
//                         </div>
//
//                         {/* Hero Flower Image - Stretched and Enlarged */}
//                         <img
//                             src={heroFlower}
//                             alt="Hero Flower"
//                             // className="absolute bottom-10 right-[0x] w-[1400px] h-[762px]  z-[-1] overflow-hidden"
//                             className="absolute bottom-0 right-0 w-[1400px] h-[762px] z-[-1] overflow-hidden"
//                             style={{ clipPath: 'inset(0 0 0 0)' }}
//                         />
//                     </div>
//                 </div>
//             </div>
//
//             <AnimatePresence>
//                 {isModalOpen && <Waitlist setModalOpen={setModalOpen}/>}
//             </AnimatePresence>
//             <AboutMentra/>
//             <JoinMentra/>
//             {/* <Testimonials /> */}
//             <Footer/>
//         </div>
//     );
// };
//
// export default HomePage;
