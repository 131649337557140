import React from "react";
import { useState, useEffect } from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
import { useSignUpContext } from "./SignUpContext";
import {toast} from "sonner";

const SignupFormFive = ({ setProgress, setStep }) => {

  useEffect(() => {
    setProgress(6);
  }, [setProgress]);

  const { setSignupData, signupData } = useSignUpContext();

  const [option, setOption] = useState(signupData.weekly_time_spent);
  const [filled, setFilled] = useState(true);


  const handleClick = () => {
    if (option) {
      setSignupData((prevData) => ({
        ...prevData,
        weekly_time_spent: option,
      }));
      setStep(7);

    } else {
      setFilled(false);
      toast.error('Please select a valid option for weekly time spent');
    }
  };

  return (
    <div>
      <div
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="flex flex-col pb-8 shadow-lg items-center h-[100%] bg-white tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] rounded-[20px] mt-3 z-1  lg:overflow-y-scroll lg:overscroll-none"
      >
        <div className="pt-[8%] laptop:pt-6 text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%] ">
          <img alt="" src={profileIcon} />
          <h1 className="text-[24px] font-bold max-[767px]:text-[20px]">
            How much time do you spend on Admin related tasks per week?
          </h1>
          <p>Select an option to proceed.</p>
        </div>

        <div className=" w-[100%]">
          <p
            className={` text-red-700 w-full ${
              filled ? "opacity-0" : "opacity-1"
            } tracking-wider text-center my-2`}
          >
            Please select an option!
          </p>
          <div className="flex flex-col justify-center items-center gap-4 mb-2 w-full">
            <button
              value="1-4 hours"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "1-4 hours" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              1-4 hours
            </button>
            <button
              value="5-9 hours"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "5-9 hours" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              5-9 hours
            </button>
            <button
              value="> 10 hours"
              onClick={(e) => setOption(e.target.value)}
              className={`w-[90%] ${
                option === "> 10 hours" ? "bg-[#1A6A73] text-white " : ""
              } text-center border-[2px] py-[3%] border-[#F9F7F3] rounded-[30px]`}
            >
              &gt; 10 hours
            </button>
          </div>
          <div>
            <button
              onClick={handleClick}
              className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-[3%] text-[white] rounded-[30px]"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupFormFive;
