import React from 'react';

import aws from "../assets/Assets/Svgs/aws.svg";
import microsoft from "../assets/Assets/Svgs/microsoft.svg";
import nividia from "../assets/Assets/Svgs/nvidia.svg";
import oxbridge from "../assets/Assets/Pngs/oxbridge.png";

const SupportedBy = () => {
    return (
        <div className="m-auto flex flex-col justify-center items-center mt-12 mb-12 lg:mt-16 lg:mb-16">
            {/*<h3 className="text-center text-lg md:text-2xl leading-5 tracking-wide mb-8">*/}
            <h3 className="text-center text-lg md:text-2xl leading-5 tracking-wide mb-8">
                Proudly supported by
            </h3>
            <div className="mb-9 flex flex-col gap-8 lg:flex-row items-center justify-evenly w-full max-w-screen-lg">
                <img
                    src={oxbridge}
                    alt="oxbridge"
                    className="h-12 sm:h-16 object-contain"
                />
                <img
                    src={nividia}
                    alt="nvidia"
                    className="h-12 sm:h-16 object-contain"
                />
                <img
                    src={microsoft}
                    alt="microsoft"
                    className="h-12 sm:h-16 object-contain"
                />
                <img
                    src={aws}
                    alt="aws"
                    className="h-12 sm:h-16 object-contain"
                />
            </div>
        </div>
    );
};

export default SupportedBy;
