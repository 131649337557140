import React from 'react';
import { useState, useEffect } from "react";
import profileIcon from "../../assets/Assets/Svgs/profileIcon.svg";
import { useSignUpContext } from "./SignUpContext";
import {toast} from "sonner";

const SignupFormX = ({ setProgress, setStep }) => {

  useEffect(() => {
    setProgress(12);
  }, [setProgress]);
  const { setSignupData, signupData } = useSignUpContext();

  const [option, setOption] = useState([...signupData.treatable_conditions]);
  const [filled, setFilled] = useState(true);


  function handleOption(e) {
    const value = e.target.value;
    if (option.includes(value)) {
      const index = option.indexOf(value);
      if (index !== -1) {
        const newOptions = [...option];
        newOptions.splice(index, 1);
        setOption(newOptions);
      }
    } else {
      setOption((option) => [value, ...option]);
    }
  }

  const handleClick = async() => {
    if (option.length > 0) {
      setSignupData((prevData) => ({
        ...prevData,
        treatable_conditions: option,
      }));
  
      setStep(13);
    } else {
      setFilled(false);
      toast.error('Please select at least one treatable condition');
    }
  };

  const conditions  = [
    "Addiction Support",
    "Anger Management",
    "Anxiety Disorders",
    "Bipolar Disorder",
    "Career Counseling",
    "Chronic Impulsivity",
    "Conflict Resolution",
    "Depression",
    "Eating Disorders",
    "Family Dynamics",
    "Grief Counseling",
    "Health and Wellness",
    "Intimacy Issues",
    "Life Transitions",
    "Parenting Support",
    "Relationship Guidance",
    "Religious Counseling",
    "Self-Esteem Building",
    "Sleep Disorders",
    "Social Behavior Challenges",
    "Stress Management",
    "Substance Abuse Recovery",
    "Trauma and Abuse Healing"
  ];
  

  return (
    <div>
      <div
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        className="flex flex-col pb-[2%] shadow-lg lg:pb-8 items-center h-[100%] bg-white  rounded-[20px] mb-[2%] mt-3  lg:mb-0 z-1 tablet:h-fit laptop:h-[70vh] laptop:max-h-[45rem] laptop:min-h-[610px]  tablet:w-[60vw] mx-auto tablet:min-w-[520px] tablet:max-w-[522px] lg:overflow-y-scroll lg:overscroll-none"
      >
        <div className="pt-[8%] text-center flex flex-col items-center border-b-[1px] border-[#E2E4E9] w-[90%] pb-[5%]">
          <img alt="" src={profileIcon} />
          <h1 className="text-[24px] font-bold ">
            I provide help with 
          </h1>
          <p>Select all that applies</p>
        </div>

        <div className=" w-[90%] px-[2%] pb-[10%] lg:pb-0 rounded-[20px] text-center">
          <p
            className={` text-red-700 w-full ${
              filled ? "opacity-0" : "opacity-1"
            } tracking-wider text-center my-2`}
          >
            Please select an option!
          </p>

          <div className="w-[100%] text-left">
            {conditions.map((word, index) => (
              <button
                value={word}
                onClick={handleOption}
                className={`px-[4%] m-[1%] ${
                  option.includes(word) ? "bg-[#1A6A73] text-white" : ""
                } border-[2px] border-[#F9F7F3] rounded-[30px] py-[2%]`}
                key={index}
              >
                {word}
              </button>
            ))}
          </div>


          <div>
            <button
              onClick={handleClick}
              className="bg-[#1A6A73] text-center w-[90%] mx-[5%] py-[4%] mt-[8%] text-[white] rounded-[30px]"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupFormX;
